import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateProjectForm from './CreateProject';
import NavigationBar from '../../NavigationBar';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { Squares2X2Icon, CalendarIcon, ListBulletIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

export default function ProjectDashboard({ showCreate = false }) {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('grid');
  const [currentDate, setCurrentDate] = useState(new Date());

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return {
      days: new Date(year, month + 1, 0).getDate(),
      firstDay: new Date(year, month, 1).getDay()
    };
  };

  const getEventsForDate = (date) => {
    // Get tasks for this date
    const tasks = projects.flatMap(project => 
      project.tasks.map(task => {
        const taskDueDate = new Date(project.dueDate.toDate());
        taskDueDate.setDate(taskDueDate.getDate() - task.relativeDeadline);
        
        if (taskDueDate.getDate() === date &&
            taskDueDate.getMonth() === currentDate.getMonth() &&
            taskDueDate.getFullYear() === currentDate.getFullYear()) {
          return {
            type: 'task',
            ...task,
            projectId: project.id,
            projectName: project.projectName,
            dueDate: taskDueDate
          };
        }
        return null;
      }).filter(Boolean)
    );

    // Get project deadlines for this date
    const deadlines = projects.map(project => {
      const projectDueDate = project.dueDate.toDate();
      
      if (projectDueDate.getDate() === date &&
          projectDueDate.getMonth() === currentDate.getMonth() &&
          projectDueDate.getFullYear() === currentDate.getFullYear()) {
        return {
          type: 'deadline',
          id: project.id,
          projectName: project.projectName,
          dueDate: projectDueDate
        };
      }
      return null;
    }).filter(Boolean);

    return [...tasks, ...deadlines];
  };

  const getAllEventsForMonth = () => {
    const events = [];
    const { days } = getDaysInMonth(currentDate);
    
    for (let i = 1; i <= days; i++) {
      const dayEvents = getEventsForDate(i);
      if (dayEvents.length > 0) {
        events.push({
          date: i,
          events: dayEvents
        });
      }
    }
    return events;
  };

  const { days, firstDay } = getDaysInMonth(currentDate);
  const totalDays = Array.from({ length: 42 }, (_, i) => {
    const dayNumber = i - firstDay + 1;
    return {
      date: dayNumber,
      isCurrentMonth: dayNumber > 0 && dayNumber <= days
    };
  });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          navigate('/login');
          return;
        }

        const db = getFirestore();
        const projectsRef = collection(db, 'projects');
        const q = query(projectsRef, where('userId', '==', user.uid));
        
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const projectsData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setProjects(projectsData);
          setLoading(false);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching projects:', error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, [navigate]);

  const handleProjectClick = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  const handleDeleteProject = async (e, projectId) => {
    e.stopPropagation(); // Prevent project click event from firing
    try {
      const db = getFirestore();
      await deleteDoc(doc(db, 'projects', projectId));
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const handleCreateClick = () => {
    navigate('/projects/create');
  };

  const handleCloseCreate = () => {
    navigate('/projects');
  };

  const getStatusColor = (status) => {
    switch(status) {
      case 'In Progress':
        return 'bg-blue-100 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300 border-blue-200 dark:border-blue-800';
      case 'Completed':
        return 'bg-green-100 dark:bg-green-900/30 text-green-700 dark:text-green-300 border-green-200 dark:border-green-800';
      case 'On Hold':
        return 'bg-yellow-100 dark:bg-yellow-900/30 text-yellow-700 dark:text-yellow-300 border-yellow-200 dark:border-yellow-800';
      case 'Overdue':
        return 'bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-300 border-red-200 dark:border-red-800';
      default:
        return 'bg-gray-100 dark:bg-black text-gray-700 dark:text-white border-gray-200 dark:border-white';
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen p-4 md:p-8">
        <NavigationBar />
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-800 dark:border-white" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <NavigationBar />
      <div className="min-h-screen p-4 md:p-8 relative">
        <div className="max-w-7xl mx-auto">
          <header className="flex flex-col md:flex-row md:items-center justify-between mb-8 md:mb-12">
            <div className="mb-6 md:mb-0">
              <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white">
                Client Projects
              </h1>
              <p className="mt-2 text-gray-600 dark:text-white/60">
                Manage your client work and track project milestones
              </p>
            </div>
            <div className="flex items-center gap-4">
              <div className="bg-white dark:bg-black rounded-lg border border-gray-200 dark:border-white p-1">
                <button
                  onClick={() => setView('grid')}
                  className={`p-2 md:px-4 md:py-2 rounded-md ${view === 'grid' ? 'bg-gray-900 dark:bg-white text-white dark:text-black' : 'text-gray-600 dark:text-white/60'}`}
                >
                  <Squares2X2Icon className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setView('calendar')}
                  className={`p-2 md:px-4 md:py-2 rounded-md ${view === 'calendar' ? 'bg-gray-900 dark:bg-white text-white dark:text-black' : 'text-gray-600 dark:text-white/60'}`}
                >
                  <CalendarIcon className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setView('list')}
                  className={`p-2 md:px-4 md:py-2 rounded-md ${view === 'list' ? 'bg-gray-900 dark:bg-white text-white dark:text-black' : 'text-gray-600 dark:text-white/60'}`}
                >
                  <ListBulletIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          </header>

          {view === 'grid' ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
              {projects.map((project) => (
                <div
                  key={project.id}
                  onClick={() => handleProjectClick(project.id)}
                  className="group relative bg-white dark:bg-black rounded-2xl p-4 md:p-6 shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 cursor-pointer border border-gray-200 dark:border-white"
                >
                  <button
                    onClick={(e) => handleDeleteProject(e, project.id)}
                    className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 p-2 rounded-full bg-white dark:bg-white hover:bg-red-100 dark:hover:bg-red-100"
                  >
                    <svg className="w-5 h-5 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                  <div className="flex justify-between items-start mb-4">
                    <h2 className="text-lg md:text-xl font-semibold text-gray-900 dark:text-white group-hover:text-gray-700 dark:group-hover:text-gray-200 transition-colors">
                      {project.projectName}
                    </h2>
                    <span className={`flex items-center gap-1 text-xs font-medium px-3 py-1 rounded-full border ${getStatusColor(project.status)}`}>
                      {project.status}
                    </span>
                  </div>
                  <div className="space-y-4">
                    <p className="text-sm text-gray-600 dark:text-white/60">
                      Due in {Math.ceil((project.dueDate.toDate() - new Date()) / (1000 * 60 * 60 * 24))} days • {project.tasks.filter(t => t.status === 'incomplete').length} tasks remaining
                    </p>
                    <div className="w-full bg-gray-200 dark:bg-white/20 rounded-full h-1.5">
                      <div 
                        className="bg-gray-900 dark:bg-white h-1.5 rounded-full transition-all duration-300" 
                        style={{
                          width: `${(project.tasks.filter(t => t.status === 'complete').length / project.tasks.length) * 100}%`
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : view === 'calendar' ? (
            <div className="bg-white dark:bg-black rounded-2xl border border-gray-200 dark:border-white p-4 md:p-6">
              <div className="flex items-center justify-between mb-6">
                <button
                  onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)))}
                  className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
                >
                  <ChevronLeftIcon className="w-5 h-5" />
                </button>
                <h2 className="text-lg font-semibold">
                  {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                </h2>
                <button
                  onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)))}
                  className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
                >
                  <ChevronRightIcon className="w-5 h-5" />
                </button>
              </div>

              <div className="grid grid-cols-7 gap-px bg-gray-200 dark:bg-gray-700 rounded-lg overflow-hidden">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                  <div key={day} className="bg-gray-50 dark:bg-gray-800 p-2 text-center font-medium hidden md:block">
                    {day}
                  </div>
                ))}
                {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(day => (
                  <div key={day} className="bg-gray-50 dark:bg-gray-800 p-2 text-center font-medium md:hidden">
                    {day}
                  </div>
                ))}
                
                {totalDays.map((day, index) => (
                  <div
                    key={index}
                    className={`min-h-[80px] md:min-h-[120px] p-2 ${
                      day.isCurrentMonth 
                        ? 'bg-white dark:bg-black' 
                        : 'bg-gray-50 dark:bg-gray-900'
                    }`}
                  >
                    {day.isCurrentMonth && (
                      <>
                        <div className="font-medium mb-2">{day.date}</div>
                        <div className="space-y-1 max-h-[60px] md:max-h-[100px] overflow-y-auto">
                          {getEventsForDate(day.date).map((event, eventIndex) => (
                            <div
                              key={eventIndex}
                              onClick={() => navigate(`/project/${event.projectId || event.id}`)}
                              className={`p-1 md:p-2 text-xs rounded cursor-pointer transition-colors ${
                                event.type === 'task' 
                                  ? 'bg-blue-50 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300 hover:bg-blue-100 dark:hover:bg-blue-800/50'
                                  : 'bg-red-50 dark:bg-red-900/30 text-red-700 dark:text-red-300 hover:bg-red-100 dark:hover:bg-red-800/50'
                              }`}
                            >
                              <div className="font-medium truncate">
                                {event.type === 'task' ? (
                                  <>{event.taskName}</>
                                ) : (
                                  <>🎯 {event.projectName} Due</>
                                )}
                              </div>
                              <div className="text-xs opacity-75 truncate hidden md:block">
                                {event.type === 'task' ? event.projectName : 'Project Deadline'}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="bg-white dark:bg-black rounded-2xl border border-gray-200 dark:border-white p-4 md:p-6">
              <div className="flex items-center justify-between mb-6">
                <button
                  onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)))}
                  className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
                >
                  <ChevronLeftIcon className="w-5 h-5" />
                </button>
                <h2 className="text-lg font-semibold">
                  {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                </h2>
                <button
                  onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)))}
                  className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
                >
                  <ChevronRightIcon className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-6">
                {getAllEventsForMonth().map(({ date, events }) => (
                  <div key={date} className="border-b border-gray-200 dark:border-gray-700 pb-4 last:border-0">
                    <h3 className="font-semibold mb-3">
                      {new Date(currentDate.getFullYear(), currentDate.getMonth(), date).toLocaleDateString('default', { 
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </h3>
                    <div className="space-y-2">
                      {events.map((event, index) => (
                        <div
                          key={index}
                          onClick={() => navigate(`/project/${event.projectId || event.id}`)}
                          className={`p-3 rounded-lg cursor-pointer transition-colors ${
                            event.type === 'task'
                              ? 'bg-blue-50 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300 hover:bg-blue-100 dark:hover:bg-blue-800/50'
                              : 'bg-red-50 dark:bg-red-900/30 text-red-700 dark:text-red-300 hover:bg-red-100 dark:hover:bg-red-800/50'
                          }`}
                        >
                          <div className="font-medium">
                            {event.type === 'task' ? event.taskName : `🎯 ${event.projectName} Due`}
                          </div>
                          <div className="text-sm opacity-75">
                            {event.type === 'task' ? event.projectName : 'Project Deadline'}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <button
          onClick={handleCreateClick}
          className="fixed bottom-4 md:bottom-8 right-4 md:right-8 group flex items-center gap-2 bg-gray-900 dark:bg-white text-white dark:text-black px-4 md:px-6 py-2 md:py-3 rounded-xl hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
        >
          <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
          </svg>
          <span>New Project</span>
        </button>

        {showCreate && (
          <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
            <div onClick={e => e.stopPropagation()}>
              <CreateProjectForm closeModal={handleCloseCreate} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
