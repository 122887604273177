import React, { useState, useEffect } from 'react';
import { db } from '../Firebase';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import mixpanel from 'mixpanel-browser';
import { 
  ChevronRightIcon, 
  DocumentIcon, 
  DocumentTextIcon, 
  TrashIcon,
  FunnelIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline';

const DocumentCard = ({ data, onPress, onSelect, selected }) => {
  const isInvoice = data.type === 'invoice';
  const Icon = isInvoice ? DocumentTextIcon : DocumentIcon;
  
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const getStatus = () => {
    if (isInvoice) {
      return data.status === 'paid' 
        ? <span className="px-2 py-0.5 text-xs rounded-full bg-green-100 text-green-800 whitespace-nowrap">Paid</span>
        : <span className="px-2 py-0.5 text-xs rounded-full bg-yellow-100 text-yellow-800 whitespace-nowrap">Pending</span>;
    } else {
      switch(data.status) {
        case 'signed':
          return <span className="px-2 py-0.5 text-xs rounded-full bg-green-100 text-green-800 whitespace-nowrap">Signed</span>;
        case 'pending':
          return <span className="px-2 py-0.5 text-xs rounded-full bg-yellow-100 text-yellow-800 whitespace-nowrap">Pending</span>;
        case 'expired':
          return <span className="px-2 py-0.5 text-xs rounded-full bg-red-100 text-red-800 whitespace-nowrap">Expired</span>;
        case 'declined':
          return <span className="px-2 py-0.5 text-xs rounded-full bg-red-100 text-red-800 whitespace-nowrap">Declined</span>;
        default:
          return <span className="px-2 py-0.5 text-xs rounded-full bg-gray-100 text-gray-800 whitespace-nowrap">Draft</span>;
      }
    }
  };

  return (
    <div 
      className={`
        group relative flex flex-col sm:flex-row items-start sm:items-center p-3 sm:p-4 
        bg-white dark:bg-black bg-opacity-50 backdrop-filter backdrop-blur-md rounded-lg 
        border border-gray-200 dark:border-gray-700 hover:border-gray-300 
        dark:hover:border-gray-600 transition-all duration-200
        shadow-md hover:shadow-lg
        ${selected ? 'border-green-500 dark:border-green-400' : ''}
      `}
    >
      <div className="flex items-center space-x-4 w-full">
        <input
          type="checkbox"
          checked={selected}
          onChange={(e) => {
            e.stopPropagation();
            onSelect(data.id);
          }}
          className="h-4 w-4 rounded-full border-gray-300 text-green-500 focus:ring-green-500 dark:bg-black"
        />
        
        <Icon className="h-6 w-6 sm:h-8 sm:w-8 text-gray-400" />
        
        <div className="flex-1 min-w-0" onClick={onPress}>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900 dark:text-white truncate mb-1 sm:mb-0 mr-2">
              {isInvoice ? data.customer : data.name}
            </p>
            {getStatus()}
          </div>
          
          <div className="flex flex-wrap gap-2 mt-1 text-xs sm:text-sm text-gray-500 dark:text-gray-400">
            <span className="truncate">
              {formatDate(isInvoice ? data.dueDate : data.date)}
            </span>
            <span className="hidden sm:inline">•</span>
            <span className="font-medium">
              ${isInvoice ? data.price : data.amount}
            </span>
            {!isInvoice && data.signatures && (
              <>
                <span className="hidden sm:inline">•</span>
                <span className="truncate">
                  {data.signatures.client ? 'Client signed' : 'Awaiting client'}
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      <ChevronRightIcon className="hidden sm:block h-5 w-5 text-gray-400 group-hover:text-gray-600 dark:group-hover:text-gray-300" />
    </div>
  );
};

const BottomHalf = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [selected, setSelected] = useState([]);
  const [sortOption, setSortOption] = useState('date');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const sortOptions = [
    { value: 'date', label: 'Latest' },
    { value: 'type', label: 'Type' },
    { value: 'amount', label: 'Amount' },
    { value: 'paid_invoices', label: 'Paid Only' },
    { value: 'signed_agreements', label: 'Signed Only' },
    { value: 'pending_agreements', label: 'Pending Only' }
  ];

  useEffect(() => {
    if (currentUser) {
      fetchDocuments();
    }
  }, [sortOption, currentUser]);

  const fetchDocuments = async () => {
    setIsLoading(true);
    try {
      const agreementsQuery = query(collection(db, 'agreements'), where('userId', '==', currentUser.uid));
      const agreementsSnapshot = await getDocs(agreementsQuery);
      const agreements = agreementsSnapshot.docs.map(doc => ({ id: doc.id, type: 'agreement', ...doc.data() }));

      const invoicesQuery = query(collection(db, 'invoices'), where('userId', '==', currentUser.uid));
      const invoicesSnapshot = await getDocs(invoicesQuery);
      const invoices = invoicesSnapshot.docs.map(doc => ({ id: doc.id, type: 'invoice', ...doc.data() }));

      let combinedData = [...agreements, ...invoices];

      switch(sortOption) {
        case 'paid_invoices':
          combinedData = combinedData.filter(item => 
            item.type === 'invoice' && item.status === 'paid'
          );
          break;
        case 'signed_agreements':
          combinedData = combinedData.filter(item => 
            item.type === 'agreement' && item.status === 'signed'
          );
          break;
        case 'pending_agreements':
          combinedData = combinedData.filter(item => 
            item.type === 'agreement' && 
            (item.status === 'pending' || !item.status)
          );
          break;
      }

      combinedData.sort((a, b) => {
        if (sortOption === 'date') {
          return new Date(b.dueDate || b.date) - new Date(a.dueDate || a.date);
        } else if (sortOption === 'type') {
          return a.type.localeCompare(b.type);
        } else if (sortOption === 'amount') {
          return (b.amount || b.price) - (a.amount || a.price);
        }
        return 0;
      });

      setDocuments(combinedData);
    } catch (error) {
      setError('Failed to load documents');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToDetailPage = (document) => {
    mixpanel.track('Navigate to Detail Page', { 'Document Type': document.type });
    if (document.type === 'agreement') {
      navigate(`/share-agreement/${document.id}`);
    } else if (document.type === 'invoice') {
      navigate(`/preview-invoice/${document.id}`);
    }
  };

  const handleDeleteDocument = async (id) => {
    try {
      const document = documents.find(document => document.id === id);
      if (document) {
        await deleteDoc(doc(db, document.type + 's', id));
        fetchDocuments();
      }
    } catch (error) {
      setError('Failed to delete document');
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      await Promise.all(selected.map(id => handleDeleteDocument(id)));
      setSelected([]);
      await fetchDocuments();
    } catch (error) {
      setError('Failed to delete selected documents');
    }
  };

  const filteredDocuments = documents.filter(doc => 
    doc.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    doc.customer?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (error) {
    return (
      <div className="rounded-lg bg-red-50 p-4 mx-auto max-w-4xl mt-4">
        <p className="text-sm text-red-700">{error}</p>
      </div>
    );
  }

  return (
    <div className="w-full px-4 sm:w-4/5 mx-auto sm:p-6">
      <div className="mb-4 sm:mb-8">
        <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:justify-between mb-4 sm:mb-6">
          <h2 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
            Recents
          </h2>
          
          <div className="flex items-center space-x-2 sm:space-x-4">
            {/* Mobile Search Icon */}
            <button 
              onClick={() => setShowSearch(!showSearch)}
              className="sm:hidden p-2 text-gray-400 hover:text-gray-600"
            >
              <MagnifyingGlassIcon className="h-5 w-5" />
            </button>

            {/* Desktop Search Bar */}
            <div className="hidden sm:block relative">
              <input
                type="text"
                placeholder="Search documents..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-8 pr-4 py-2 text-sm border border-gray-300 rounded-lg bg-white 
                         dark:bg-gray-800 dark:border-gray-700 dark:text-white focus:outline-none 
                         focus:ring-2 focus:ring-green-500 focus:border-transparent
                         shadow-sm w-64"
              />
              <MagnifyingGlassIcon className="h-4 w-4 text-gray-400 absolute left-2 top-1/2 -translate-y-1/2" />
            </div>

            {selected.length > 0 && (
              <button
                onClick={handleDelete}
                className="inline-flex items-center justify-center px-3 py-2 text-sm font-medium 
                         text-red-700 bg-red-100 rounded-lg hover:bg-red-200 focus:outline-none 
                         focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
              >
                <TrashIcon className="h-4 w-4 mr-1.5" />
                <span className="hidden sm:inline">Delete</span> {selected.length}
              </button>
            )}
            
            <div className="relative">
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className="pl-8 pr-4 py-2 text-sm border border-gray-300 rounded-lg bg-white dark:bg-gray-800 
                         dark:border-gray-700 dark:text-white appearance-none focus:outline-none 
                         focus:ring-2 focus:ring-orange-500 cursor-pointer w-32 sm:w-auto"
              >
                {sortOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <FunnelIcon className="h-4 w-4 text-gray-400 absolute left-2 top-1/2 -translate-y-1/2" />
            </div>

            <button
              onClick={() => navigate('/invoices')}
              className="hidden sm:block px-4 py-2 text-sm font-medium text-white bg-black rounded-lg 
                       hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 
                       focus:ring-gray-500 transition-colors duration-200 shadow-sm"
            >
              View All
            </button>
          </div>
        </div>

        {/* Mobile Search Bar */}
        {showSearch && (
          <div className="sm:hidden relative mt-2">
            <input
              type="text"
              placeholder="Search documents..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-8 pr-4 py-2 text-sm border border-gray-300 rounded-lg bg-white 
                       dark:bg-gray-800 dark:border-gray-700 dark:text-white focus:outline-none 
                       focus:ring-2 focus:ring-green-500 focus:border-transparent
                       shadow-sm w-full"
            />
            <MagnifyingGlassIcon className="h-4 w-4 text-gray-400 absolute left-2 top-1/2 -translate-y-1/2" />
          </div>
        )}
      </div>

      <div className="space-y-3">
        {isLoading ? (
          <div className="flex items-center justify-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-500" />
          </div>
        ) : filteredDocuments.length > 0 ? (
          filteredDocuments.map((doc) => (
            <DocumentCard
              key={doc.id}
              data={doc}
              onPress={() => navigateToDetailPage(doc)}
              onSelect={(id) => {
                setSelected(prev => 
                  prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]
                );
              }}
              selected={selected.includes(doc.id)}
            />
          ))
        ) : (
          <div className="text-center py-8 sm:py-12 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
            <DocumentIcon className="mx-auto h-8 w-8 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">
              {searchQuery ? 'No matching documents' : 'No documents'}
            </h3>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              {searchQuery ? 'Try a different search term' : 'Get started by creating a new document'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BottomHalf;
