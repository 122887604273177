import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { IoIosArrowBack, IoMdRefresh, IoMdCheckmarkCircleOutline, IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import { FiType } from 'react-icons/fi';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../Firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { getAuth } from 'firebase/auth';
import NavigationBar from '../../NavigationBar';

// Updated SIGNATURE_FONTS with sample descriptions
const SIGNATURE_FONTS = [
  { name: 'Signature', value: 'Dancing Script', sample: 'Elegant flowing style' },
  { name: 'Formal', value: 'Pinyon Script', sample: 'Professional look' },
  { name: 'Classic', value: 'Alex Brush', sample: 'Traditional style' },
  { name: 'Modern', value: 'Great Vibes', sample: 'Contemporary feel' },
];

const FontPreview = ({ font, text, isSelected, onClick }) => (
  <button
    onClick={onClick}
    className={`w-full p-4 border rounded-lg transition-all ${
      isSelected 
        ? 'border-green-500 bg-green-50 dark:bg-green-900/20' 
        : 'border-gray-200 dark:border-gray-700 hover:border-green-300'
    }`}
  >
    <div
      className="text-xl mb-2"
      style={{ fontFamily: font.value }}
    >
      {text || 'Preview'}
    </div>
    <div className="text-sm text-gray-500 dark:text-gray-400">
      {font.name} - {font.sample}
    </div>
  </button>
);

const TypedSignature = ({ onSignatureComplete, signatureText, font }) => {
  return (
    <div 
      className="w-full h-full flex items-center justify-center"
      style={{ fontFamily: font, fontSize: '2rem' }}
    >
      {signatureText || 'Your Signature'}
    </div>
  );
};

const SigningInstructions = () => (
  <div className="mb-8">
    <h2 className="font-bold text-lg mb-5">Klorah Sign</h2>
    <ol className="list-decimal pl-4 font-bold">
      <li>Draw your signature in the signature pad below.</li>
      <li>Position your signature on the agreement preview.</li>
      <li>Click "Sign Agreement" to complete the signing process.</li>
    </ol>
  </div>
);

const SignaturePreview = ({ font, text }) => (
  <div
    className="p-4 text-center text-2xl"
    style={{ 
      fontFamily: font,
      minHeight: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    {text || 'Preview'}
  </div>
);

const SignaturePage = () => {
  const { agreementId } = useParams();
  const navigate = useNavigate();
  const [agreement, setAgreement] = useState(null);
  const [freelancerSignatures, setFreelancerSignatures] = useState([]);
  const [clientSignatures, setClientSignatures] = useState([]);
  const [signaturePosition, setSignaturePosition] = useState({ x: 0, y: 0 });
  const [signatureSize, setSignatureSize] = useState({ width: 100, height: 50 });
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [signingAs, setSigningAs] = useState('freelancer');

  const sigCanvas = useRef({});
  const agreementRef = useRef(null);
  const signatureRef = useRef(null);
  const containerRef = useRef(null);

  const [showSignFlow, setShowSignFlow] = useState(false);
  const [signatureMethod, setSignatureMethod] = useState('type'); // 'type' or 'draw'
  const [signatureText, setSignatureText] = useState('');
  const [selectedFont, setSelectedFont] = useState(SIGNATURE_FONTS[0].value);
  const [signerEmail, setSignerEmail] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [step, setStep] = useState(1); // 1: Info, 2: Signature, 3: Position

  const [touchStartPosition, setTouchStartPosition] = useState({ x: 0, y: 0 });

  const toggleSignFlow = () => {
    setShowSignFlow(!showSignFlow);
  };

  useEffect(() => {
    const fetchAgreement = async () => {
      setLoading(true);
      const docRef = doc(db, 'agreements', agreementId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAgreement(docSnap.data());
        setLoading(false);
      } else {
        setError('No such document!');
        setLoading(false);
      }
    };

    const checkAuth = () => {
      const auth = getAuth();
      const isUserAuthenticated = !!auth.currentUser;
      setIsAuthenticated(isUserAuthenticated);
      setSigningAs(isUserAuthenticated ? 'freelancer' : 'client');
    };

    fetchAgreement();
    checkAuth();
  }, [agreementId]);

  useEffect(() => {
    const WebFont = window.WebFont;
    if (WebFont) {
      WebFont.load({
        google: {
          families: [
            'Dancing Script',
            'Great Vibes',
            'Pinyon Script',
            'Alex Brush'
          ]
        }
      });
    }
  }, []);

  useEffect(() => {
    if (freelancerSignatures.length > 0 || clientSignatures.length > 0) {
      const documentElement = agreementRef.current;
      if (documentElement) {
        const newPosition = {
          x: 50,
          y: documentElement.offsetHeight - 100
        };
        setSignaturePosition(newPosition);
      }
    }
  }, [freelancerSignatures, clientSignatures]);

  const sendEmail = async (email, subject, htmlContent) => {
    try {
      const response = await fetch('https://klorah-fast-server-9266fe8d441a.herokuapp.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          to: email,
          subject: subject,
          html_content: htmlContent
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      const result = await response.json();
      toast.success('Email sent successfully');
      console.log('Email response:', result);
    } catch (error) {
      toast.error('Error sending email: ' + error.message);
      console.error('Error sending email:', error);
    }
  };

  const handleSignatureEnd = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    canvas.width = 500;
    canvas.height = 150;
    
    ctx.font = `48px ${selectedFont}`;
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    
    ctx.fillText(signatureText, canvas.width / 2, canvas.height / 2);
    
    const sigData = canvas.toDataURL('image/png');
    
    if (signingAs === 'freelancer') {
      setFreelancerSignatures([...freelancerSignatures, sigData]);
    } else {
      setClientSignatures([...clientSignatures, sigData]);
    }
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const handleMouseDown = (e) => {
    if (signatureRef.current && signatureRef.current.contains(e.target)) {
      setIsDragging(true);
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const rect = agreementRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left - signatureRef.current.offsetWidth / 2;
      const y = e.clientY - rect.top - signatureRef.current.offsetHeight / 2;
      setSignaturePosition({ x, y });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    if (signatureRef.current && signatureRef.current.contains(e.target)) {
      const touch = e.touches[0];
      setTouchStartPosition({
        x: touch.clientX - signaturePosition.x,
        y: touch.clientY - signaturePosition.y
      });
      setIsDragging(true);
    }
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      e.preventDefault(); // Prevent scrolling while dragging
      const touch = e.touches[0];
      const rect = agreementRef.current.getBoundingClientRect();
      
      // Direct positioning without offset calculations for more responsive feel
      const newX = touch.clientX - rect.left - (signatureRef.current?.offsetWidth || 0) / 2;
      const newY = touch.clientY - rect.top - (signatureRef.current?.offsetHeight || 0) / 2;
      
      // Add bounds checking with some padding
      const maxX = rect.width - (signatureRef.current?.offsetWidth || 0) - 10;
      const maxY = rect.height - (signatureRef.current?.offsetHeight || 0) - 10;
      
      const boundedX = Math.max(10, Math.min(newX, maxX));
      const boundedY = Math.max(10, Math.min(newY, maxY));
      
      setSignaturePosition({ x: boundedX, y: boundedY });
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleResize = (e) => {
    const { name, value } = e.target;
    setSignatureSize((prevSize) => ({
      ...prevSize,
      [name]: value,
    }));
  };

  const signDocument = async () => {
    setLoading(true);
    try {
      if (!signatureText || !signerEmail || !termsAccepted) {
        setError('Please complete all required fields');
        return;
      }

      if ((freelancerSignatures.length > 0 || clientSignatures.length > 0) && agreementRef.current) {
        // Create PDF of signed document
        const canvas = await html2canvas(agreementRef.current, {
          scale: 2,
          useCORS: true,
          logging: true,
        });

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: [canvas.width, canvas.height],
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
        const pdfBlob = pdf.output('blob');

        // Upload signed PDF to Firebase Storage
        const storageRef = ref(storage, `signed_agreements/${agreementId}.pdf`);
        await uploadBytes(storageRef, pdfBlob);
        const downloadUrl = await getDownloadURL(storageRef);

        // Update Firestore document with signature status and document link
        const agreementDoc = doc(db, 'agreements', agreementId);
        await updateDoc(agreementDoc, {
          status: 'signed',
          signedUrl: downloadUrl,
          [`${signingAs}Signature`]: true,
          signedAt: new Date().toISOString(),
          [`${signingAs}Email`]: signerEmail,
          [`${signingAs}Name`]: signatureText,
          lastUpdated: new Date().toISOString(),
          // Track who signed and when
          signatures: {
            [signingAs]: {
              name: signatureText,
              email: signerEmail,
              signedAt: new Date().toISOString(),
              signatureUrl: downloadUrl
            }
          }
        });

        // Send email notification
        const emailSubject = 'Document Signed Successfully';
        const documentUrl = `https://klorah.app/download-signed-agreement/${agreementId}`;
        const emailContent = `
          <h1>Document Signed Successfully</h1>
          <p>The document "${agreement?.name || 'Agreement'}" has been signed by ${signatureText}.</p>
          <p>You can access the signed document at: <a href="${documentUrl}">${documentUrl}</a></p>
          <p>Status: Signed</p>
          <p>Signed by: ${signatureText}</p>
          <p>Signed on: ${new Date().toLocaleDateString()}</p>
        `;

        await sendEmail(signerEmail, emailSubject, emailContent);
        
        // If this was the client signing, also notify the freelancer
        if (signingAs === 'client' && agreement?.freelancerEmail) {
          await sendEmail(agreement.freelancerEmail, 
            'Your Agreement Has Been Signed', 
            emailContent
          );
        }

        toast.success('Document signed successfully!');
        navigate(`/download-signed-agreement/${agreementId}`);
      } else {
        setError('Please add your signature before signing the document');
      }
    } catch (error) {
      console.error('Signing error:', error);
      setError(`Error signing document: ${error.message}`);
      toast.error('Failed to sign document. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const SignaturePositioningGuide = () => (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg z-50">
      <div className="flex items-center space-x-2">
        <div className="animate-pulse bg-green-500 h-2 w-2 rounded-full" />
        <p className="text-sm">Click and drag your signature to position it on the document</p>
      </div>
    </div>
  );

  const MobilePositioningGuide = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
      // Reduce the timeout to 3 seconds
      const timer = setTimeout(() => setIsVisible(false), 3000);
      return () => clearTimeout(timer);
    }, []);

    if (!isVisible) return null;

    return (
      <div className="fixed top-4 left-4 right-4 bg-black/75 text-white p-3 rounded-lg z-50 text-center transform animate-fade-out">
        <p className="text-sm">
          Tap and hold to drag signature
        </p>
      </div>
    );
  };

  const renderSignaturePanel = () => {
    switch(step) {
      case 1:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Signer Information</h3>
            <div>
              <label className="block text-sm font-medium mb-1">Full Name</label>
              <input
                type="text"
                value={signatureText}
                onChange={(e) => setSignatureText(e.target.value)}
                className="w-full p-2 border rounded-md dark:bg-gray-700"
                placeholder="Enter your full name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Email</label>
              <input
                type="email"
                value={signerEmail}
                onChange={(e) => setSignerEmail(e.target.value)}
                className="w-full p-2 border rounded-md dark:bg-gray-700"
                placeholder="Enter your email"
              />
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                className="rounded"
              />
              <label className="text-sm">
                I agree to use an electronic signature on this document
              </label>
            </div>
            <button
              className="w-full bg-green-500 text-white p-2 rounded-lg"
              onClick={() => setStep(2)}
              disabled={!signatureText || !signerEmail || !termsAccepted}
            >
              Continue
            </button>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <h3 className="text-lg font-semibold text-center">Choose Your Signature Style</h3>
            <p className="text-sm text-center text-gray-600 dark:text-gray-400">
              Select a font style that best represents your signature
            </p>
            <div className="grid grid-cols-1 gap-4">
              {SIGNATURE_FONTS.map((font) => (
                <FontPreview
                  key={font.value}
                  font={font}
                  text={signatureText}
                  isSelected={selectedFont === font.value}
                  onClick={() => setSelectedFont(font.value)}
                />
              ))}
            </div>
            <button
              className="w-full bg-green-500 text-white p-3 rounded-lg flex items-center justify-center"
              onClick={() => {
                handleSignatureEnd();
                setStep(3);
              }}
            >
              Continue to Positioning
            </button>
          </div>
        );

      case 3:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-center">Position Your Signature</h3>
            <div className="bg-yellow-50 dark:bg-yellow-900 p-4 rounded-lg mb-2">
              <p className="text-sm">
                {window.innerWidth <= 768 
                  ? "Tap and hold your signature to move it" 
                  : "Click and drag your signature to place it anywhere on the document"}
              </p>
            </div>
            {window.innerWidth <= 768 ? <MobilePositioningGuide /> : <SignaturePositioningGuide />}
            <div className="sticky bottom-0 bg-white dark:bg-gray-800 p-4 -mx-4 mt-4 border-t">
              <button
                className="w-full bg-green-500 text-white p-3 rounded-lg flex items-center justify-center shadow-lg"
                onClick={signDocument}
              >
                <IoMdCheckmarkCircleOutline size={24} className="mr-2" />
                Complete Signing
              </button>
            </div>
          </div>
        );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white min-h-screen">
      {isAuthenticated && <NavigationBar />}
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-2/3 p-5 overflow-auto">
          <SigningInstructions />
          {agreement ? (
            <div
              ref={agreementRef}
              className="agreement-content relative"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              style={{ 
                padding: '20px', 
                border: '1px solid #ddd', 
                borderRadius: '8px',
                touchAction: isDragging ? 'none' : 'auto',
                WebkitOverflowScrolling: 'touch' // For smoother scrolling on iOS
              }}
            >
              <div className="text-gray-700 dark:text-gray-200 whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: agreement.text }} />
              {(freelancerSignatures.length > 0 || clientSignatures.length > 0) && (
                <div 
                  className={`absolute ${
                    isDragging ? 'scale-105' : ''
                  }`}
                  style={{ 
                    left: signaturePosition.x, 
                    top: signaturePosition.y,
                    transform: 'translate3d(0,0,0)', // Force GPU acceleration
                    willChange: 'transform', // Optimize for animations
                    cursor: 'move',
                    touchAction: 'none'
                  }}
                >
                  {freelancerSignatures.map((signatureData, index) => (
                    <img
                      key={index}
                      ref={signatureRef}
                      src={signatureData}
                      alt="Signature"
                      className={`${
                        isDragging ? 'ring-2 ring-green-500' : ''
                      }`}
                      style={{ 
                        width: `${signatureSize.width}px`, 
                        height: `${signatureSize.height}px`,
                        userSelect: 'none',
                        WebkitUserSelect: 'none'
                      }}
                    />
                  ))}
                  {clientSignatures.map((signatureData, index) => (
                    <img
                      key={index}
                      ref={signatureRef}
                      src={signatureData}
                      alt="Signature"
                      className={`${
                        isDragging ? 'ring-2 ring-green-500' : ''
                      }`}
                      style={{ 
                        width: `${signatureSize.width}px`, 
                        height: `${signatureSize.height}px`,
                        userSelect: 'none',
                        WebkitUserSelect: 'none'
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">No agreement available. Please wait...</p>
            </div>
          )}
        </div>
        <div className="sticky top-0 w-full md:w-1/3 p-5 border-b md:border-b-0 md:border-l bg-white dark:bg-gray-800">
          {showSignFlow ? renderSignaturePanel() : (
            <div className="flex justify-center items-center h-full">
              <button
                className="bg-green-500 text-white p-2 rounded-lg flex items-center justify-center"
                onClick={toggleSignFlow}
              >
                <IoMdCheckmarkCircleOutline size={24} className="mr-2" /> 
                Sign Document
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignaturePage;