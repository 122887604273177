import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { useOnboarding } from './OnboardingContext';
import { doc, setDoc } from 'firebase/firestore';
import { db, auth } from '../Firebase';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe('pk_live_51IuUXbLwpaCHfpZ1EZNNYaQYdXSIrE6NrdA3r7lUQOIq5tsdhC7MaTITIMFb3nW4yxqyppRd8C66QcxWhuoPC8aa00Ow2q0o0z');

const PRICE_IDS = {
  monthly: 'price_1QMa81LwpaCHfpZ1sR2trgFd',
  quarterly: 'price_1QMa81LwpaCHfpZ1EEsytrKu'
};

const basicFeatures = [
  '2 free invoices per month',
  '2 free client agreements per month',
  'Bookable profiles',
  'Basic reporting',
  'Mobile app access'
];

const proFeatures = [
  'Unlimited invoices',
  'Unlimited client agreements',
  'Custom branded invoices',
  'Priority support',
];

export default function MembershipBilling() {
  const { isOnboardingComplete, updateOnboardingStatus, isLoading, user } = useOnboarding();
  const navigate = useNavigate();
  const [billingPeriod, setBillingPeriod] = useState('quarterly');
  const [isProcessing, setIsProcessing] = useState(false);

  const createStripeCustomer = async () => {
    try {
      const customerRes = await axios.post('https://klorah-fast-server-9266fe8d441a.herokuapp.com/create-customer', {
        email: user.email,
      }, {
        headers: {
          Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
        },
      });
      return { stripeCustomerId: customerRes.data.stripe_customer_id, userId: user.uid };
    } catch (error) {
      console.error('Error creating Stripe customer:', error);
      return null;
    }
  };

  const createCheckoutSession = async ({ stripeCustomerId, userId }) => {
    try {
      const sessionRes = await axios.post('https://klorah-fast-server-9266fe8d441a.herokuapp.com/createCheckoutSession', {
        userId,
        stripeCustomerId,
        priceId: PRICE_IDS[billingPeriod],
        billingPeriod
      });

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: sessionRes.data.sessionId });
      localStorage.setItem('isOnboardingComplete', 'true');
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setIsProcessing(false);
    }
  };

  const initializeFreeTrialCounts = async () => {
    if (user) {
      try {
        await setDoc(doc(db, 'users', user.uid), {
          monthlyInvoiceCount: 0,
          monthlyInvoiceLimit: 2,
          monthlyResetDate: new Date(),
          isPro: false
        }, { merge: true });
      } catch (error) {
        console.error('Error initializing account:', error);
      }
    }
  };

  const handlePlanSelection = async (isPro) => {
    try {
      await updateOnboardingStatus(true);
      if (isPro) {
        setIsProcessing(true);
        const customerData = await createStripeCustomer();
        if (customerData) {
          await createCheckoutSession(customerData);
        } else {
          setIsProcessing(false);
        }
      } else {
        await initializeFreeTrialCounts();
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error:', error);
      setIsProcessing(false);
    }
  };

  if (isLoading || isProcessing) return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-orange-500" />
      <p className="ml-4 text-gray-600">Setting up your subscription...</p>
    </div>
  );
  
  if (!user) return <div>Please log in to continue</div>;

  return (
    <div className="min-h-screen bg-white dark:bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white">
            Choose your plan
          </h1>
          <p className="mt-4 text-xl text-gray-500 dark:text-gray-400">
            Start with our Basic plan or unlock unlimited possibilities with Pro
          </p>
        </div>

        {/* Billing Period Toggle */}
        <div className="flex justify-center mt-8">
          <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-1 inline-flex">
            <button
              onClick={() => setBillingPeriod('monthly')}
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                billingPeriod === 'monthly'
                  ? 'bg-white dark:bg-gray-700 shadow text-gray-900 dark:text-white'
                  : 'text-gray-500 dark:text-gray-400'
              }`}
            >
              Monthly
            </button>
            <button
              onClick={() => setBillingPeriod('quarterly')}
              className={`px-4 py-2 rounded-md text-sm font-medium flex items-center gap-2 ${
                billingPeriod === 'quarterly'
                  ? 'bg-white dark:bg-gray-700 shadow text-gray-900 dark:text-white'
                  : 'text-gray-500 dark:text-gray-400'
              }`}
            >
              <span>3 Months</span>
              {billingPeriod === 'quarterly' && (
                <span className="bg-orange-100 text-orange-800 text-xs px-2 py-0.5 rounded-full">
                  Save 47%
                </span>
              )}
            </button>
          </div>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 max-w-4xl mx-auto">
          {/* Basic Plan */}
          <div className="rounded-2xl p-8 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
            <div>
              <h3 className="text-xl">Basic</h3>
              <p className="text-gray-500 dark:text-gray-400">
                Perfect for getting started
              </p>
              
              <div className="mt-6">
                <span className="text-3xl font-semibold">$0</span>
                <span className="text-sm text-gray-500 dark:text-gray-400">/forever</span>
              </div>

              <ul className="mt-8 space-y-4">
                {basicFeatures.map((feature) => (
                  <li key={feature} className="flex items-start">
                    <CheckIcon 
                      className="h-5 w-5 shrink-0 text-orange-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <button
              onClick={() => handlePlanSelection(false)}
              className="mt-8 w-full rounded-lg py-3 text-center bg-black text-white hover:bg-gray-900 dark:bg-white dark:text-black dark:hover:bg-gray-100"
            >
              Continue
            </button>
          </div>

          {/* Pro Plan */}
          <div className="rounded-2xl p-8 bg-black text-white relative">
            <div className="inline-block mb-4">
              <span className="bg-orange-500 text-white px-3 py-1 rounded-full text-sm">
                Most Popular
              </span>
            </div>

            <div>
              <h3 className="text-xl">Pro</h3>
              <p className="text-gray-300">
                Best for growing businesses
              </p>
              
              <div className="mt-6">
                <span className="text-3xl font-semibold">
                  ${billingPeriod === 'quarterly' ? '8' : '15'}
                </span>
                <span className="text-sm text-gray-300">/month</span>
                {billingPeriod === 'quarterly' && (
                  <div className="mt-2">
                    <span className="text-sm text-gray-300">
                      Billed ${8 * 3} every 3 months
                    </span>
                  </div>
                )}
              </div>

              <ul className="mt-8 space-y-4">
                {proFeatures.map((feature) => (
                  <li key={feature} className="flex items-start">
                    <CheckIcon 
                      className="h-5 w-5 shrink-0 text-orange-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <button
              onClick={() => handlePlanSelection(true)}
              className="mt-8 w-full rounded-lg py-3 text-center bg-white text-black hover:bg-gray-100"
            >
              Upgrade to Pro
            </button>
          </div>
        </div>

        <p className="mt-12 text-center text-sm text-gray-500 dark:text-gray-400">
          All plans include 24/7 basic support and our 30-day money-back guarantee
        </p>
      </div>
    </div>
  );
}