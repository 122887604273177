import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../NavigationBar';
import useCurrentUser from '../Authentication/currentUser';
import LoadingPage from '../Dashboard/Agreement/loadingPage';
import DarkModeButton from '../DarkModeButton';

const WalletView = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [balance, setBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isProcessingPayout, setIsProcessingPayout] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.onboardingComplete || !currentUser.stripeOnboarded) {
        navigate('/stripe-ob');
      }
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (currentUser?.stripeAccountId) {
      fetchBalance(currentUser.stripeAccountId);
    }
  }, [currentUser]);

  const fetchBalance = async (accountId) => {
    setIsLoading(true);
    try {
      setError('');
      const response = await fetch(`https://klorah-fast-server-9266fe8d441a.herokuapp.com/balance/${accountId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch balance: ${response.statusText}`);
      }
      const data = await response.json();
      setBalance(data);
    } catch (error) {
      console.error('Fetch error:', error);
      setError('Failed to fetch balance. Please try again later.');
    }
    setIsLoading(false);
  };

  const handleInstantPayout = async () => {
    if (!balance?.available[0]?.amount || balance.available[0].amount <= 0) {
      alert('No available balance for payout');
      return;
    }

    setIsProcessingPayout(true);
    try {
      const response = await fetch('https://klorah-fast-server-9266fe8d441a.herokuapp.com/create-instant-payout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accountId: currentUser.stripeAccountId,
          amount: balance.available[0].amount,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to process instant payout');
      }

      const data = await response.json();
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
      fetchBalance(currentUser.stripeAccountId);
    } catch (error) {
      console.error('Payout error:', error);
      alert('Failed to process payout. Please try again.');
    } finally {
      setIsProcessingPayout(false);
    }
  };

  const transactions = [
    { date: '2022-01-01', amount: 100 },
    { date: '2022-01-02', amount: 200 },
  ];

  if (isLoading) {
    return (
      <div>
        <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
          <NavigationBar />
        </div>
        <div className="flex justify-center items-center h-screen">
          <LoadingPage />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white min-h-screen">
      <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
        <NavigationBar />
      </div>
      
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          {error && (
            <div className="mb-4 p-4 bg-opacity-10 rounded-lg border">
              {error}
            </div>
          )}
          
          <div className="bg-white dark:bg-black rounded-xl shadow-lg p-8 mb-8">
            {balance && (
              <div className="space-y-6">
                <h2 className="text-3xl font-bold text-center mb-8">
                  Wallet Overview
                </h2>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="p-6 border rounded-lg">
                    <p className="text-sm font-medium mb-2">Available Balance</p>
                    <p className="text-3xl font-bold">
                      ${(balance.available[0].amount / 100).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </p>
                  </div>
                  
                  <div className="p-6 border rounded-lg">
                    <p className="text-sm font-medium mb-2">Pending Balance</p>
                    <p className="text-3xl font-bold">
                      ${(balance.pending[0].amount / 100).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col md:flex-row gap-4 mt-8">
              <button
                className="flex-1 px-6 py-3 bg-white dark:bg-black border rounded-lg font-medium transition-all duration-300 hover:bg-opacity-90 flex items-center justify-center gap-2"
                onClick={() => window.open('https://connect.stripe.com/app/express', '_blank')}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                </svg>
                Manage Payout Settings
              </button>
              
              <button
                className="flex-1 px-6 py-3 bg-white dark:bg-black border rounded-lg font-medium transition-all duration-300 hover:bg-opacity-90 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
                onClick={handleInstantPayout}
                disabled={isProcessingPayout || !balance?.available[0]?.amount || balance.available[0].amount <= 0}
              >
                {isProcessingPayout ? (
                  <>
                    <svg className="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                    </svg>
                    Instant Payout
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showSuccessMessage && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg animate-fade-in-out">
          Payout processed successfully!
        </div>
      )}
    </div>
  );
};

export default WalletView;
