import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, increment, collection, addDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import NavigationBar from '../../NavigationBar';
import LoadingPage from '../Agreement/loadingPage';
import { VerificationBanner } from '../VerificationBanner';
import DarkModeButton from '../../DarkModeButton';
import InvoiceForm from './InvoiceForm';
import InvoicePreview from './InvoicePreview';
import ThemeModal from './ThemeModal';
import ProModal from './ProModal';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function CreateInvoice() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isProUser, setIsProUser] = useState(false);
  const [freeProInvoicesRemaining, setFreeProInvoicesRemaining] = useState(2);
  const [monthlyInvoiceCount, setMonthlyInvoiceCount] = useState(0);
  const [showLimitWarning, setShowLimitWarning] = useState(false);
  const [showProModal, setShowProModal] = useState(false);
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const [invoiceTheme, setInvoiceTheme] = useState('default');
  const [showInvoicePreview, setShowInvoicePreview] = useState(false);
  const [createdInvoiceId, setCreatedInvoiceId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    gig: '',
    customer: '',
    currency: 'USD',
    price: '',
    dueDate: new Date(),
    userEmail: '',
    customEmailSubject: '',
    customEmailContent: '',
    status: 'draft',
    createdAt: new Date(),
    updatedAt: new Date()
  });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        await fetchUserStatus(user.uid);
        setLoading(false);
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (location.state) {
      const { projectId, clientId, customer, userEmail, source } = location.state;
      
      setInvoiceData(prevData => ({
        ...prevData,
        projectId,
        clientId,
        customer: customer || prevData.customer,
        userEmail: userEmail || prevData.userEmail,
        source: source || 'project'
      }));
    }
  }, [location.state]);

  const checkAndResetMonthlyCount = async (userData, userId) => {
    const now = new Date();
    const resetDate = userData.monthlyResetDate?.toDate() || now;
    
    if (now.getMonth() !== resetDate.getMonth() || now.getFullYear() !== resetDate.getFullYear()) {
      const firestore = getFirestore();
      await updateDoc(doc(firestore, 'users', userId), {
        monthlyInvoiceCount: 0,
        monthlyResetDate: now
      });
      return 0;
    }
    return userData.monthlyInvoiceCount || 0;
  };

  const fetchUserStatus = async (userId) => {
    try {
      const firestore = getFirestore();
      const docRef = doc(firestore, 'users', userId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const isFullPro = userData.isPro || false;
        setIsProUser(isFullPro);
        
        if (!isFullPro) {
          const currentCount = await checkAndResetMonthlyCount(userData, userId);
          const remaining = 2 - currentCount;
          setFreeProInvoicesRemaining(remaining);
          setMonthlyInvoiceCount(currentCount);
          
          if (remaining <= 1) {
            setShowLimitWarning(true);
          }
        }
      }
    } catch (error) {
      setError('Failed to load user data. Please refresh the page.');
    }
  };

  const handleInvoiceCreation = async () => {
    if (!isProUser) {
      if (freeProInvoicesRemaining <= 0) {
        setShowProModal(true);
        return false;
      }
      
      try {
        const firestore = getFirestore();
        await updateDoc(doc(firestore, 'users', currentUser.uid), {
          monthlyInvoiceCount: increment(1)
        });
        setMonthlyInvoiceCount(prev => prev + 1);
        setFreeProInvoicesRemaining(prev => prev - 1);
      } catch (error) {
        setError('Failed to update invoice count. Please try again.');
        return false;
      }
    }
    return true;
  };

  const createInvoice = async () => {
    try {
      const firestore = getFirestore();
      const invoiceRef = await addDoc(collection(firestore, 'invoices'), {
        ...invoiceData,
        userId: currentUser.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
        theme: invoiceTheme,
        status: 'draft',
        isProInvoice: true
      });
      
      setCreatedInvoiceId(invoiceRef.id);
      return invoiceRef.id;
    } catch (error) {
      throw new Error('Failed to create invoice');
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    
    setSubmitting(true);
    setError(null);
    
    try {
      const canCreateInvoice = await handleInvoiceCreation();
      if (!canCreateInvoice) {
        setSubmitting(false);
        return;
      }

      const invoiceId = await createInvoice();
      navigate(`/invoice-details/${invoiceId}`);
    } catch (error) {
      setError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return <LoadingPage />;

  if (!currentUser?.emailVerified) {
    return (
      <div className="text-black dark:text-white min-h-screen">
        <NavigationBar />
        <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="text-lg text-red-600">Please verify your email to create invoices.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white min-h-screen">
      <NavigationBar />
      <DarkModeButton />
      
      {!isProUser && (
        <div className="bg-gradient-to-r from-green-100 to-green-50 dark:from-green-900/50 dark:to-green-800/50 px-4 py-3">
          <div className="max-w-7xl mx-auto flex items-center justify-between">
            <p className="text-green-800 dark:text-green-200 text-sm flex items-center gap-2">
              <span className="font-medium text-green-600 dark:text-green-400">{freeProInvoicesRemaining}</span>
              free pro invoices remaining this month
              {showLimitWarning && (
                <span className="text-green-600 dark:text-green-300 font-medium">
                  • Last free pro invoice!
                </span>
              )}
            </p>
            <button 
              onClick={() => setShowProModal(true)}
              className="text-sm bg-green-500 hover:bg-green-600 text-white px-4 py-1.5 rounded-full transition-colors duration-200"
            >
              Upgrade to Unlimited
            </button>
          </div>
        </div>
      )}

      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 p-4">
          <div className="max-w-7xl mx-auto flex items-center justify-between">
            <p className="text-red-800 dark:text-red-200">{error}</p>
            <button onClick={() => setError(null)}>
              <XMarkIcon className="h-5 w-5 text-red-800 dark:text-red-200" />
            </button>
          </div>
        </div>
      )}

      <div className="mx-auto max-w-7xl px-4 pb-24 pt-16 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-green-600 dark:text-green-400 mb-8">Create Invoice</h1>
        <form onSubmit={handleFormSubmit} className="space-y-8 lg:grid lg:grid-cols-2 lg:gap-x-14 xl:gap-x-16 lg:space-y-0">
          <div>
            <div className="lg:block hidden">
              <InvoicePreview 
                invoiceData={invoiceData} 
                invoiceTheme={invoiceTheme} 
                setIsThemeModalOpen={setIsThemeModalOpen}
                invoiceId={createdInvoiceId}
              />
            </div>
            <div className="lg:hidden">
              <button
                type="button"
                onClick={() => setShowInvoicePreview(!showInvoicePreview)}
                className="my-6 w-full bg-gray-100 text-black py-3 rounded-lg hover:bg-gray-200 transition duration-300 dark:bg-black dark:text-white dark:hover:bg-gray-900 dark:border dark:border-gray-800"
              >
                {showInvoicePreview ? 'Hide Preview' : 'Show Preview'}
              </button>
              {showInvoicePreview && (
                <InvoicePreview 
                  invoiceData={invoiceData} 
                  invoiceTheme={invoiceTheme} 
                  setIsThemeModalOpen={setIsThemeModalOpen}
                  invoiceId={createdInvoiceId}
                />
              )}
            </div>
          </div>

          <div className="flex justify-center w-full">
            <InvoiceForm 
              invoiceData={invoiceData} 
              setInvoiceData={setInvoiceData}
              currentUser={currentUser}
              isProUser={true}
              setShowProModal={setShowProModal}
              remainingInvoices={freeProInvoicesRemaining}
              submitting={submitting}
              isFreeProInvoice={!isProUser && freeProInvoicesRemaining > 0}
            />
          </div>
        </form>
      </div>

      <VerificationBanner />
      
      {showProModal && (
        <ProModal 
          setShowProModal={setShowProModal}
          freeProInvoicesRemaining={freeProInvoicesRemaining}
          monthlyInvoiceLimit={2}
          onUpgradeSuccess={() => fetchUserStatus(currentUser.uid)}
          isForUnlimited={true}
        />
      )}
      
      <ThemeModal 
        isOpen={isThemeModalOpen} 
        setIsOpen={setIsThemeModalOpen}
        invoiceTheme={invoiceTheme}
        setInvoiceTheme={setInvoiceTheme}
      />
    </div>
  );
}