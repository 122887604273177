import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import NavigationBar from '../../NavigationBar';

const ProjectDetails = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [agreements, setAgreements] = useState([]);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddTask, setShowAddTask] = useState(false);
  const [newTaskName, setNewTaskName] = useState('');
  const [newTaskDeadline, setNewTaskDeadline] = useState('');

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) throw new Error('Must be logged in');

        const db = getFirestore();

        // Fetch project
        const projectDoc = await getDoc(doc(db, 'projects', projectId));
        if (!projectDoc.exists()) throw new Error('Project not found');
        
        const projectData = projectDoc.data();
        setProject({ id: projectDoc.id, ...projectData });

        // Fetch client
        if (projectData.clientId) {
          const clientDoc = await getDoc(doc(db, 'users', user.uid, 'clients', projectData.clientId));
          if (clientDoc.exists()) {
            setClient({ id: clientDoc.id, ...clientDoc.data() });
          }
        }

        // Fetch associated invoices
        if (projectData.invoices?.length > 0) {
          const invoiceDocs = await Promise.all(
            projectData.invoices.map(id => getDoc(doc(db, 'invoices', id)))
          );
          setInvoices(invoiceDocs.map(doc => ({ id: doc.id, ...doc.data() })));
        }

        // Fetch associated agreements
        if (projectData.agreements?.length > 0) {
          const agreementDocs = await Promise.all(
            projectData.agreements.map(id => getDoc(doc(db, 'agreements', id)))
          );
          setAgreements(agreementDocs.map(doc => ({ id: doc.id, ...doc.data() })));
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
        setError('Failed to load project data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [projectId]);

  const handleTaskStatusToggle = async (taskIndex) => {
    if (!project) return;

    try {
      const updatedTasks = project.tasks.map((task, index) => {
        if (index === taskIndex) {
          return { ...task, status: task.status === 'complete' ? 'incomplete' : 'complete' };
        }
        return task;
      });

      // Check if all tasks are complete
      const allTasksComplete = updatedTasks.every(task => task.status === 'complete');
      const newStatus = allTasksComplete ? 'Completed' : 'In Progress';

      const db = getFirestore();
      const projectRef = doc(db, 'projects', projectId);
      
      // Update both tasks and status
      await updateDoc(projectRef, { 
        tasks: updatedTasks,
        status: newStatus
      });

      // Update local state
      setProject((prev) => ({ 
        ...prev, 
        tasks: updatedTasks,
        status: newStatus
      }));
    } catch (error) {
      console.error('Error updating task status:', error);
      setError('Failed to update task status. Please try again.');
    }
  };

  const handleAddTask = async () => {
    if (!newTaskName || !newTaskDeadline) return;

    try {
      const newTask = {
        taskName: newTaskName,
        relativeDeadline: parseInt(newTaskDeadline),
        status: 'incomplete',
        id: Date.now()
      };

      const updatedTasks = [...project.tasks, newTask];
      
      const db = getFirestore();
      const projectRef = doc(db, 'projects', projectId);
      await updateDoc(projectRef, { tasks: updatedTasks });

      setProject((prev) => ({ ...prev, tasks: updatedTasks }));
      setNewTaskName('');
      setNewTaskDeadline('');
      setShowAddTask(false);
    } catch (error) {
      console.error('Error adding task:', error);
      setError('Failed to add task. Please try again.');
    }
  };

  const handleCreateInvoice = () => {
    if (client && project) {
      navigate('/create-invoice', {
        state: {
          projectId: project.id,
          clientId: client.id,
          customer: client.name,
          userEmail: client.email,
          gig: project.name,
          source: 'project'
        }
      });
    }
  };

  const handleCreateAgreement = () => {
    if (client) {
      navigate('/create-agreement', {
        state: {
          projectId: project.id,
          clientId: client.id,
          clientName: client.name,
          source: 'project'
        }
      });
    }
  };

  const handleCompleteAllTasks = async () => {
    if (!project) return;

    try {
      const updatedTasks = project.tasks.map(task => ({
        ...task,
        status: 'complete'
      }));

      const db = getFirestore();
      const projectRef = doc(db, 'projects', projectId);
      
      await updateDoc(projectRef, { 
        tasks: updatedTasks,
        status: 'Completed'
      });

      setProject((prev) => ({ 
        ...prev, 
        tasks: updatedTasks,
        status: 'Completed'
      }));
    } catch (error) {
      console.error('Error completing all tasks:', error);
      setError('Failed to complete all tasks. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-800 dark:border-white"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-black">
        <div className="text-center p-8 bg-white dark:bg-black rounded-xl shadow-lg max-w-md border border-gray-200 dark:border-white">
          <div className="text-red-500 text-5xl mb-4">⚠️</div>
          <p className="text-gray-800 dark:text-white mb-6">{error}</p>
          <button
            onClick={() => navigate('/projects')}
            className="px-6 py-3 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-all duration-200 shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
          >
            Back to Projects
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
        <NavigationBar />
        <div className="min-h-screen p-6 md:p-8">
        <div className="max-w-4xl mx-auto">
            <div className="bg-white dark:bg-black rounded-2xl shadow-xl overflow-hidden border border-gray-200 dark:border-white">
            <div className="p-6 md:p-8 border-b border-gray-200 dark:border-white">
                <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
                <div className="flex items-center gap-3">
                  <h1 className="text-3xl font-bold text-gray-900 dark:text-white">{project.projectName}</h1>
                  <button
                    onClick={() => navigate(`/project/${projectId}/edit`)}
                    className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
                    title="Edit project"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                </div>
                <div className="flex items-center gap-4">
                    <div className="flex flex-col items-end">
                    <span className="text-sm text-gray-500 dark:text-white/60">Due Date</span>
                    <span className="text-md font-medium text-gray-900 dark:text-white">
                        {new Date(project.dueDate.seconds * 1000).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                        })}
                    </span>
                    </div>
                    <span
                    className={`px-4 py-2 rounded-full text-sm font-semibold ${
                        project.status === 'Completed'
                        ? 'bg-green-500 text-white'
                        : 'bg-yellow-500 text-white'
                    }`}
                    >
                    {project.status}
                    </span>
                </div>
                </div>
            </div>

            <div className="p-6 md:p-8">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Tasks Progress</h2>
                  <div className="flex gap-2">
                    {project.tasks.some(task => task.status === 'incomplete') && (
                      <button
                        onClick={handleCompleteAllTasks}
                        className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-all duration-200"
                      >
                        Complete All Tasks
                      </button>
                    )}
                    <button
                      onClick={() => setShowAddTask(!showAddTask)}
                      className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                      title="Add task"
                    >
                      <svg className={`w-6 h-6 transition-transform duration-200 ${showAddTask ? 'rotate-45' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                    </button>
                  </div>
                </div>

                {showAddTask && (
                  <div className="mb-6 p-4 bg-gray-50 dark:bg-gray-900 rounded-xl border border-gray-200 dark:border-gray-700 animate-slideDown">
                    <div className="flex flex-col md:flex-row gap-4">
                      <input
                        type="text"
                        value={newTaskName}
                        onChange={(e) => setNewTaskName(e.target.value)}
                        placeholder="Task name"
                        className="flex-1 rounded-lg border border-gray-200 dark:border-gray-700 px-4 py-2 bg-white dark:bg-black"
                      />
                      <select
                        value={newTaskDeadline}
                        onChange={(e) => setNewTaskDeadline(e.target.value)}
                        className="rounded-lg border border-gray-200 dark:border-gray-700 px-4 py-2 bg-white dark:bg-black appearance-none pr-10 pl-4"
                      >
                        <option value="">&nbsp;&nbsp;Days before due&nbsp;&nbsp;</option>
                        <option value="1">1 day before</option>
                        <option value="3">3 days before</option>
                        <option value="7">7 days before</option>
                        <option value="14">14 days before</option>
                      </select>
                      <button
                        onClick={handleAddTask}
                        disabled={!newTaskName || !newTaskDeadline}
                        className="px-6 py-2 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        Add Task
                      </button>
                    </div>
                  </div>
                )}

                {project.tasks.length > 0 ? (
                <div className="space-y-4">
                    {project.tasks.map((task, index) => (
                    <div
                      key={index}
                      onClick={() => handleTaskStatusToggle(index)}
                      className="group bg-gray-50 dark:bg-black rounded-xl p-4 transition-all duration-200 hover:shadow-md border border-gray-200 dark:border-white cursor-pointer"
                    >
                      <div className="flex items-center justify-between gap-4">
                        <div className="flex-1">
                          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-1">
                            {task.taskName}
                          </h3>
                          <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-white/60">
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <span>{task.relativeDeadline} days before due date</span>
                          </div>
                        </div>
                        <div className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                          task.status === 'complete'
                            ? 'bg-green-500 text-white'
                            : 'bg-white dark:bg-black text-gray-900 dark:text-white border border-gray-200 dark:border-white'
                        }`}>
                          {task.status === 'complete' ? '✓ Completed' : 'Click to Complete'}
                        </div>
                      </div>
                    </div>
                    ))}
                </div>
                ) : (
                <div className="text-center py-12 bg-gray-50 dark:bg-black rounded-xl border border-gray-200 dark:border-white">
                    <p className="text-gray-500 dark:text-white/60">No tasks added yet.</p>
                </div>
                )}
            </div>

            {/* Invoices section */}
            <div className="p-6 md:p-8 border-t border-gray-200 dark:border-white">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Invoices</h2>
                <button 
                  onClick={handleCreateInvoice}
                  className="px-4 py-2 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-all duration-200"
                >
                  New Invoice
                </button>
              </div>
              {invoices.map(invoice => (
                <div key={invoice.id} className="mb-4 p-4 border border-gray-200 dark:border-white rounded-lg">
                  {/* Invoice card content */}
                </div>
              ))}
            </div>

            {/* Agreements section */}
            <div className="p-6 md:p-8 border-t border-gray-200 dark:border-white">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Agreements</h2>
                <button 
                  onClick={handleCreateAgreement}
                  className="px-4 py-2 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-all duration-200"
                >
                  New Agreement
                </button>
              </div>
              {agreements.map(agreement => (
                <div key={agreement.id} className="mb-4 p-4 border border-gray-200 dark:border-white rounded-lg">
                  {/* Agreement card content */}
                </div>
              ))}
            </div>

            <div className="p-6 md:p-8 border-t border-gray-200 dark:border-white">
                <button
                onClick={() => navigate('/projects')}
                className="w-full md:w-auto px-6 py-3 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-all duration-200 flex items-center justify-center gap-2"
                >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                </svg>
                Back to Projects
                </button>
            </div>
            </div>
        </div>
        </div>
    </div>
  );
};

export default ProjectDetails;
