import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import LoadingPage from '../Agreement/loadingPage';
import NavigationBar from '../../NavigationBar';
import { IoMdClipboard } from 'react-icons/io';
import { FaEye } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import InvoicePreview from './InvoicePreview';

export default function PreviewInvoice() {
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPreview, setShowPreview] = useState(true);

  useEffect(() => {
    const firestore = getFirestore();
    const fetchInvoiceDetails = async () => {
      setIsLoading(true);
      try {
        const invoiceRef = doc(firestore, "invoices", invoiceId);
        const docSnap = await getDoc(invoiceRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.dueDate) {
            try {
              const date = data.dueDate.toDate ? data.dueDate.toDate() : new Date(data.dueDate);
              data.dueDate = date instanceof Date && !isNaN(date) ? date : null;
            } catch (e) {
              data.dueDate = null;
            }
          }
          setInvoiceDetails(data);
        } else {
          throw new Error("Invoice not found");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoiceDetails();
  }, [invoiceId]);

  if (isLoading) return <LoadingPage />;
  if (error) return <div className="flex justify-center items-center h-screen"><p>Error: {error}</p></div>;
  if (!invoiceDetails) return <div className="flex justify-center items-center h-screen"><p>No invoice details available.</p></div>;

  const statusColor = invoiceDetails.status === 'paid' ? 'text-green-500' : 'text-red-500';

  return (
    <div className="min-h-screen bg-white dark:bg-black">
      <NavigationBar />
      
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white dark:bg-black shadow-sm rounded-xl border border-gray-200 dark:border-white">
          <div className="p-6 md:p-8">
            <div className="flex items-center justify-between mb-6">
              <h1 className="text-2xl font-semibold dark:text-white">Invoice Details</h1>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${statusColor}`}>
                {invoiceDetails.status?.toUpperCase() || 'PENDING'}
              </span>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
              <div>
                <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Invoice ID</div>
                <div className="font-mono text-gray-900 dark:text-white">{invoiceId}</div>
              </div>
              <div>
                <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Amount</div>
                <div className="text-gray-900 dark:text-white">{invoiceDetails.currency} {invoiceDetails.price}</div>
              </div>
              <div>
                <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Service</div>
                <div className="text-gray-900 dark:text-white">{invoiceDetails.gig}</div>
              </div>
              <div>
                <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Due Date</div>
                <div className="text-gray-900 dark:text-white">{invoiceDetails.dueDate?.toLocaleDateString() || 'Not specified'}</div>
              </div>
            </div>

            <div className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <button
                  onClick={() => setShowPreview(!showPreview)}
                  className="w-full px-4 py-3 border border-gray-200 dark:border-white rounded-lg inline-flex items-center justify-center text-sm text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-900 transition-all duration-200"
                >
                  <FaEye className="mr-2" size={16} />
                  {showPreview ? 'Hide Preview' : 'Show Preview'}
                </button>
                <button
                  onClick={() => {
                    const link = `${window.location.origin}/payment/${invoiceId}`;
                    navigator.clipboard.writeText(link).then(() => {
                      toast.success("Payment link copied");
                    });
                  }}
                  className="w-full px-4 py-3 border border-gray-200 dark:border-white rounded-lg inline-flex items-center justify-center text-sm text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-900 transition-all duration-200"
                >
                  <IoMdClipboard className="mr-2" size={16} />
                  Copy Payment Link
                </button>
              </div>

              {invoiceDetails.status !== 'paid' && (
                <button
                  onClick={() => navigate(`/payment/${invoiceId}`)}
                  className="w-full px-4 py-3 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg text-sm font-medium hover:bg-gray-800 dark:hover:bg-gray-200 transition-all duration-200"
                >
                  Proceed to Payment Page
                </button>
              )}
            </div>
          </div>

          {showPreview && (
            <div className="border-t border-gray-200 dark:border-white p-6 md:p-8">
              <InvoicePreview 
                invoiceData={invoiceDetails}
                invoiceId={invoiceId}
              />
            </div>
          )}
        </div>
      </div>
      
      <ToastContainer 
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss={false}
        draggable={false}
        theme="light"
      />
    </div>
  );
}