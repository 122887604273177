import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import LoadingPage from './Agreement/loadingPage';
import NavigationBar from '../NavigationBar';
import { CheckIcon, SparklesIcon } from '@heroicons/react/24/solid';
import axios from 'axios';

const proFeatures = [
  {
    title: 'Unlimited Documents',
    description: 'Create unlimited invoices and agreements'
  },
  {
    title: 'Premium Templates',
    description: 'Access to exclusive professional templates'
  },
  {
    title: 'AI Assistant',
    description: 'Smart document generation and analysis'
  },
  {
    title: 'White Labeling',
    description: 'Remove Klorah branding, add your own'
  },
  {
    title: 'Priority Support',
    description: 'Get help when you need it most'
  }
];

const SubscriptionStatus = () => {
  const [subscription, setSubscription] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userStats, setUserStats] = useState({
    remainingInvoices: 0,
    remainingAgreements: 0,
    totalCreated: 0
  });
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const profileRef = doc(db, 'users', user.uid);
          const profileSnapshot = await getDoc(profileRef);
          
          if (profileSnapshot.exists()) {
            const profileData = profileSnapshot.data();
            setSubscription(profileData.isPro ? 'Pro' : 'Basic');
            
            if (!profileData.isPro) {
              setUserStats({
                remainingInvoices: 2 - (profileData.monthlyInvoiceCount || 0),
                remainingAgreements: 2 - (profileData.monthlyAgreementCount || 0),
                totalCreated: (profileData.monthlyInvoiceCount || 0) + (profileData.monthlyAgreementCount || 0)
              });
            }
          }
        } catch (error) {
          setError("Failed to load user data");
        }
      } else {
        navigate('/login');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleManageSubscription = async () => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated.");

      const idToken = await user.getIdToken();
      const profileRef = doc(db, 'users', user.uid);
      const profileSnapshot = await getDoc(profileRef);
      if (!profileSnapshot.exists()) 
        throw new Error("User profile not found.");

      const profileData = profileSnapshot.data();
      const customerId = profileData.stripeCustomerId;
      
      if (!customerId) 
        throw new Error("Stripe customer ID not found.");

      const response = await axios.post(
        'https://klorah-fast-server-9266fe8d441a.herokuapp.com/create-customer-portal-session',
        { userId: user.uid },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      
      window.location.href = response.data.url;
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) return <LoadingPage />;

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-black">
      <NavigationBar />
      
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        {subscription === 'Pro' ? (
          <div className="space-y-8">
            <div className="bg-gradient-to-r from-orange-500 to-pink-500 rounded-2xl p-8 text-white">
              <div className="flex justify-between items-start">
                <div>
                  <div className="flex items-center space-x-2">
                    <SparklesIcon className="h-6 w-6" />
                    <h1 className="text-2xl font-bold">Pro Plan</h1>
                  </div>
                  <p className="mt-2 text-orange-100">You have unlimited access to all premium features</p>
                </div>
                <button
                  onClick={handleManageSubscription}
                  className="px-4 py-2 bg-white/20 hover:bg-white/30 rounded-lg text-sm font-medium 
                           backdrop-blur-sm transition-all"
                >
                  Manage Subscription
                </button>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {proFeatures.map((feature) => (
                <div 
                  key={feature.title}
                  className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <CheckIcon className="h-6 w-6 text-green-500" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900 dark:text-white">{feature.title}</h3>
                      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="space-y-8">
            <div className="bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-sm">
              <div className="flex justify-between items-center">
                <div>
                  <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Free Plan</h1>
                  <p className="mt-2 text-gray-600 dark:text-gray-400">You have limited access to premium features</p>
                </div>
              </div>

              <div className="mt-8 grid grid-cols-2 gap-6">
                <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-6">
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Pro Invoices Left</h3>
                  <div className="mt-2 flex items-baseline">
                    <span className="text-3xl font-bold text-gray-900 dark:text-white">
                      {userStats.remainingInvoices}
                    </span>
                    <span className="ml-2 text-sm text-gray-500 dark:text-gray-400">/ 2</span>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-6">
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Pro Agreements Left</h3>
                  <div className="mt-2 flex items-baseline">
                    <span className="text-3xl font-bold text-gray-900 dark:text-white">
                      {userStats.remainingAgreements}
                    </span>
                    <span className="ml-2 text-sm text-gray-500 dark:text-gray-400">/ 2</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-sm">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">
                Unlock All Features with Pro
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {proFeatures.map((feature) => (
                  <div 
                    key={feature.title}
                    className="flex items-start space-x-3"
                  >
                    <CheckIcon className="h-5 w-5 text-gray-400 dark:text-gray-500 flex-shrink-0" />
                    <div>
                      <h3 className="font-medium text-gray-900 dark:text-white">{feature.title}</h3>
                      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
                <div className="flex items-end justify-between">
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Starting at</p>
                    <div className="mt-1 flex items-baseline">
                      <span className="text-3xl font-bold text-gray-900 dark:text-white">$8</span>
                      <span className="ml-1 text-gray-500 dark:text-gray-400">/month</span>
                    </div>
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">when billed quarterly</p>
                  </div>
                  <button
                    onClick={() => navigate('/membership-billing')}
                    className="px-6 py-3 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-lg 
                             font-medium hover:from-green-600 hover:to-green-700 transition-all shadow-sm"
                  >
                    Upgrade Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {error && (
          <div className="mt-8 bg-red-50 dark:bg-red-900/50 rounded-xl p-4">
            <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
          </div>
        )}
      </main>
    </div>
  );
};

export default SubscriptionStatus;
