import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp, doc, updateDoc, arrayUnion, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';

const CreateProjectForm = ({ closeModal }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [clients, setClients] = useState([]);
  const [showClientDropdown, setShowClientDropdown] = useState(false);
  
  const [projectData, setProjectData] = useState({
    projectName: '',
    clientId: '',
    clientName: '',
    clientEmail: '',
    dueDate: new Date(),
    tasks: [],
    status: 'In Progress',
    invoices: [],
    agreements: [],
    totalAmount: 0
  });

  // Pre-fill from client card if coming from there
  useEffect(() => {
    const locationState = location.state;
    if (locationState?.source === 'client_card') {
      setProjectData(prev => ({
        ...prev,
        clientId: locationState.clientId,
        clientName: locationState.clientName,
      }));
    }
  }, [location]);

  useEffect(() => {
    const fetchClients = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const clientsRef = collection(getFirestore(), 'users', user.uid, 'clients');
        const unsubscribe = onSnapshot(clientsRef, snapshot => {
          const clientsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setClients(clientsData);
        });
        return () => unsubscribe();
      }
    };

    fetchClients();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) throw new Error('Must be logged in');

      const db = getFirestore();

      // Create project document
      const projectRef = await addDoc(collection(db, 'projects'), {
        ...projectData,
        userId: user.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      // Update client document with reference to this project
      if (projectData.clientId) {
        const clientRef = doc(db, 'users', user.uid, 'clients', projectData.clientId);
        await updateDoc(clientRef, {
          projects: arrayUnion(projectRef.id)
        });
      }

      navigate(`/project/${projectRef.id}`);
    } catch (error) {
      setError('Failed to create project: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const [taskName, setTaskName] = useState('');
  const [relativeDeadline, setRelativeDeadline] = useState('');
  const [currentStep, setCurrentStep] = useState(1);

  // Helper function to format date for input
  const formatDateForInput = (date) => {
    return date.toISOString().split('T')[0];
  };

  // Get minimum date (today)
  const minDate = formatDateForInput(new Date());

  const addTask = () => {
    if (!taskName || !relativeDeadline) {
      setError('Please provide both a task name and deadline');
      return;
    }
    setProjectData(prev => ({
      ...prev,
      tasks: [
        ...prev.tasks,
        {
          taskName,
          relativeDeadline: parseInt(relativeDeadline, 10),
          status: 'incomplete',
          id: Date.now()
        }
      ]
    }));
    setTaskName('');
    setRelativeDeadline('');
    setError('');
  };

  const removeTask = (index) => {
    setProjectData(prev => ({
      ...prev,
      tasks: prev.tasks.filter((_, i) => i !== index)
    }));
  };

  const editTask = (index) => {
    const taskToEdit = projectData.tasks[index];
    setTaskName(taskToEdit.taskName);
    setRelativeDeadline(taskToEdit.relativeDeadline);
    setProjectData(prev => ({
      ...prev,
      tasks: prev.tasks.filter((_, i) => i !== index)
    }));
  };

  const nextStep = () => {
    if (currentStep === 1 && (!projectData.projectName || !projectData.dueDate || !projectData.clientId)) {
      setError('Please fill in all required fields including client selection');
      return;
    }
    if (currentStep === 2 && projectData.tasks.length === 0) {
      setError('Add at least one task before proceeding');
      return;
    }
    setError('');
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleClose = () => {
    if (typeof closeModal === 'function') {
      closeModal();
    }
  };

  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white dark:bg-black rounded-3xl w-full max-w-[580px] p-8 relative overflow-y-auto max-h-[90vh] shadow-2xl"
      >
        {/* Progress Bar */}
        <div className="absolute top-0 left-0 right-0 h-1 bg-gray-100 dark:bg-black">
          <div 
            className="h-full bg-green-500 transition-all duration-300 ease-out"
            style={{ width: `${(currentStep / 3) * 100}%` }}
          />
        </div>

        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300 transition-colors"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        {/* Header */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
            {currentStep === 1 ? 'Project Details' : currentStep === 2 ? 'Add Tasks' : 'Review Project'}
          </h2>
          <p className="text-gray-500 dark:text-white mt-2">
            Step {currentStep} of 3: {
              currentStep === 1 ? 'Define your project scope' : 
              currentStep === 2 ? 'Break it down into manageable tasks' :
              'Review and confirm project details'
            }
          </p>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/30 text-red-900 dark:text-red-200 text-sm rounded-xl border border-red-100 dark:border-red-800">
            <div className="flex items-center gap-2">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className="text-red-500">
                <path d="M10 6v4m0 4h.01M19 10a9 9 0 11-18 0 9 9 0 0118 0z" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
              </svg>
              {error}
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {currentStep === 1 ? (
            <>
              {/* Project Details Step */}
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700 dark:text-white">
                    What's your project called?
                  </label>
                  <input
                    type="text"
                    value={projectData.projectName}
                    onChange={(e) => setProjectData(prev => ({...prev, projectName: e.target.value}))}
                    placeholder="e.g., Website Redesign"
                    className="w-full px-4 py-3 rounded-xl border border-gray-200 dark:border-white bg-white dark:bg-black placeholder:text-gray-400 dark:placeholder:text-white focus:ring-2 focus:ring-green-500 dark:focus:ring-green-600 outline-none transition-shadow"
                  />
                </div>

                {/* Add Client Selection Here */}
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700 dark:text-white">
                    Select Client
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={projectData.clientName}
                      placeholder="Choose a client"
                      onClick={() => setShowClientDropdown(!showClientDropdown)}
                      readOnly
                      className="w-full px-4 py-3 rounded-xl border border-gray-200 dark:border-white bg-white dark:bg-black placeholder:text-gray-400 dark:placeholder:text-white focus:ring-2 focus:ring-green-500 dark:focus:ring-green-600 outline-none transition-shadow cursor-pointer"
                    />
                    {showClientDropdown && (
                      <div className="absolute z-10 w-full mt-1 bg-white dark:bg-black border border-gray-200 dark:border-white rounded-xl shadow-lg max-h-60 overflow-y-auto">
                        {clients.map(client => (
                          <div
                            key={client.id}
                            onClick={() => {
                              setProjectData(prev => ({
                                ...prev,
                                clientId: client.id,
                                clientName: client.name,
                                clientEmail: client.email
                              }));
                              setShowClientDropdown(false);
                            }}
                            className="px-4 py-3 hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                          >
                            <div className="font-medium text-gray-900 dark:text-white">{client.name}</div>
                            {client.email && (
                              <div className="text-sm text-gray-500 dark:text-gray-400">{client.email}</div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700 dark:text-white">
                    When does it need to be completed?
                  </label>
                  <input
                    type="date"
                    min={minDate}
                    value={formatDateForInput(projectData.dueDate)}
                    onChange={(e) => setProjectData(prev => ({...prev, dueDate: new Date(e.target.value)}))}
                    className="w-full px-4 py-3 rounded-xl border border-gray-200 dark:border-white bg-white dark:bg-black focus:ring-2 focus:ring-green-500 dark:focus:ring-green-600 outline-none transition-shadow"
                  />
                </div>
              </div>
            </>
          ) : currentStep === 2 ? (
            <>
              {/* Tasks Step */}
              <div className="space-y-6">
                <div className="flex items-center justify-between">
                  <div className="space-y-1">
                    <h3 className="font-medium text-gray-900 dark:text-white">Project Tasks</h3>
                    <p className="text-sm text-gray-500 dark:text-white">Add tasks and set their deadlines relative to the project end date</p>
                  </div>
                  <span className="px-3 py-1 bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-300 text-sm rounded-full">
                    {projectData.tasks.length} tasks
                  </span>
                </div>

                <div className="flex gap-3">
                  <input
                    type="text"
                    value={taskName}
                    onChange={(e) => setTaskName(e.target.value)}
                    placeholder="What needs to be done?"
                    className="flex-1 px-4 py-3 rounded-xl border border-gray-200 dark:border-white bg-white dark:bg-black placeholder:text-gray-400 dark:placeholder:text-white focus:ring-2 focus:ring-green-500 dark:focus:ring-green-600 outline-none transition-shadow"
                  />
                  <select
                    value={relativeDeadline}
                    onChange={(e) => setRelativeDeadline(e.target.value)}
                    className="w-40 px-4 py-3 rounded-xl border border-gray-200 dark:border-white bg-white dark:bg-black focus:ring-2 focus:ring-green-500 dark:focus:ring-green-600 outline-none transition-shadow"
                  >
                    <option value="">Deadline</option>
                    <option value="1">1 day before</option>
                    <option value="3">3 days before</option>
                    <option value="7">1 week before</option>
                    <option value="14">2 weeks before</option>
                    <option value="30">1 month before</option>
                  </select>
                  <button
                    type="button"
                    onClick={addTask}
                    className="px-6 py-3 bg-green-500 hover:bg-green-600 text-white rounded-xl transition-colors"
                  >
                    Add Task
                  </button>
                </div>

                {projectData.tasks.length > 0 && (
                  <div className="space-y-3 mt-6">
                    {projectData.tasks.map((task, index) => (
                      <div
                        key={task.id}
                        className="flex items-center justify-between p-4 rounded-xl border border-gray-200 dark:border-white bg-gray-50 dark:bg-black group hover:border-green-200 dark:hover:border-green-800 transition-colors"
                      >
                        <div>
                          <h4 className="font-medium text-gray-900 dark:text-white">{task.taskName}</h4>
                          <p className="text-sm text-gray-500 dark:text-white">Due {task.relativeDeadline} days before project end</p>
                        </div>
                        <div className="flex items-center gap-2">
                          <button
                            type="button"
                            onClick={() => editTask(index)}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            onClick={() => removeTask(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="space-y-6">
              <div>
                <h3 className="font-bold text-lg text-gray-900 dark:text-white">Project Summary</h3>
                <p className="text-gray-500 dark:text-white">Review your project details before submission.</p>
                <div className="mt-4 space-y-4">
                  <div className="p-4 rounded-xl bg-gray-50 dark:bg-black border dark:border-white">
                    <p className="text-sm text-gray-500 dark:text-white mb-1">Project Name</p>
                    <p className="text-gray-900 dark:text-white font-medium">{projectData.projectName}</p>
                  </div>
                  <div className="p-4 rounded-xl bg-gray-50 dark:bg-black border dark:border-white">
                    <p className="text-sm text-gray-500 dark:text-white mb-1">Client</p>
                    <p className="text-gray-900 dark:text-white font-medium">{projectData.clientName}</p>
                  </div>
                  <div className="p-4 rounded-xl bg-gray-50 dark:bg-black border dark:border-white">
                    <p className="text-sm text-gray-500 dark:text-white mb-1">Due Date</p>
                    <p className="text-gray-900 dark:text-white font-medium">{projectData.dueDate.toLocaleDateString()}</p>
                  </div>
                  <div className="p-4 rounded-xl bg-gray-50 dark:bg-black border dark:border-white">
                    <p className="text-sm text-gray-500 dark:text-white mb-2">Tasks ({projectData.tasks.length})</p>
                    <ul className="space-y-2">
                      {projectData.tasks.map((task, index) => (
                        <li key={index} className="flex justify-between items-center text-sm">
                          <span className="text-gray-900 dark:text-white">{task.taskName}</span>
                          <span className="text-gray-500 dark:text-white">{task.relativeDeadline} days before</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Navigation Buttons */}
          <div className="flex justify-between pt-6 border-t border-gray-200 dark:border-white">
            {currentStep > 1 ? (
              <button
                type="button"
                onClick={prevStep}
                className="px-6 py-3 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-black rounded-xl transition-colors"
              >
                Back
              </button>
            ) : (
              <div></div>
            )}
            
            {currentStep < 3 ? (
              <button
                type="button"
                onClick={nextStep}
                className="px-6 py-3 bg-green-500 hover:bg-green-600 text-white rounded-xl transition-colors"
              >
                Continue
              </button>
            ) : (
              <button
                type="submit"
                disabled={loading}
                className="px-6 py-3 bg-green-500 hover:bg-green-600 text-white rounded-xl transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <span className="flex items-center gap-2">
                    <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"/>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                    </svg>
                    Creating Project...
                  </span>
                ) : (
                  'Create Project'
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateProjectForm;