import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faUsers, faPen } from '@fortawesome/free-solid-svg-icons';
import { collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../Firebase';
import useCurrentUser from '../Authentication/currentUser';

const UserNameFrame = () => {
  const { username } = useCurrentUser();
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(username);
  const [showEditIcon, setShowEditIcon] = useState(false);

  // Update editedName when username changes
  useEffect(() => {
    setEditedName(username);
  }, [username]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editedName.trim() && editedName !== username) {
      try {
        const userDoc = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userDoc, { username: editedName.trim() });
      } catch (error) {
        console.error('Error updating username:', error);
      }
    }
    setIsEditing(false);
  };

  return (
    <div className="flex justify-center items-center py-3">
      <h1 className="text-2xl font-light text-black dark:text-white">
        Hello,{' '}
        <span
          className="relative group"
          onMouseEnter={() => setShowEditIcon(true)}
          onMouseLeave={() => !isEditing && setShowEditIcon(false)}
        >
          {isEditing ? (
            <form onSubmit={handleSubmit} className="inline-flex items-center">
              <input
                type="text"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                className="font-bold bg-transparent border-b border-gray-400 focus:outline-none focus:border-black dark:focus:border-white px-1"
                autoFocus
                onBlur={handleSubmit}
              />
            </form>
          ) : (
            <>
              <span className="font-bold">{username}</span>
              {showEditIcon && (
                <FontAwesomeIcon
                  icon={faPen}
                  className="ml-2 text-sm cursor-pointer opacity-50 hover:opacity-100"
                  onClick={() => setIsEditing(true)}
                />
              )}
            </>
          )}
        </span>
      </h1>
    </div>
  );
};

const TopHalf = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);

  useEffect(() => {
    const user = auth.currentUser;
    if (user && user.uid) {
      const invoicesQuery = query(collection(db, 'invoices'), where('userId', '==', user.uid));
      const clientsQuery = query(collection(db, `users/${user.uid}/clients`));

      const unsubscribeInvoices = onSnapshot(invoicesQuery, snapshot => {
        setInvoiceCount(snapshot.size);
      });
      const unsubscribeClients = onSnapshot(clientsQuery, snapshot => {
        setClientCount(snapshot.size);
      });

      return () => {
        unsubscribeInvoices();
        unsubscribeClients();
      };
    }
  }, [currentUser]);

  return (
    <div className="flex flex-col items-center">
      <UserNameFrame />
      <div className="flex w-full justify-around px-10">

        <div className="bg-black text-white p-4 rounded-xl flex flex-col items-center m-2 shadow-xl hover:shadow-2xl transition-shadow duration-300 ease-in-out cursor-pointer dark:bg-white dark:text-black dark:border dark:border-white" style={{ minWidth: '165px' }} onClick={() => navigate('/invoices')}>
          <FontAwesomeIcon icon={faFileInvoice} size="2x" />
          <div className="text-xl font-bold mt-2">Invoices</div>
          <div className="text-3xl font-bold">{invoiceCount}</div>
          <div>Total</div>
          <button className="mt-2 bg-white text-black py-2 px-4 rounded-full text-sm hover:bg-gray-300 transition-colors dark:bg-black dark:text-white dark:hover:bg-gray-800 dark:border dark:border-white" onClick={(e) => { e.stopPropagation(); navigate('/invoices'); }}>
            View All
          </button>
        </div>
        
        <div className="bg-white text-black p-4 rounded-xl flex flex-col items-center m-2 shadow-xl hover:shadow-2xl transition-shadow duration-300 ease-in-out cursor-pointer dark:bg-black dark:text-white dark:border dark:border-white" style={{ minWidth: '165px' }} onClick={() => navigate('/clients')}>
          <FontAwesomeIcon icon={faUsers} size="2x" />
          <div className="text-xl font-bold mt-2">Clients</div>
          <div className="text-3xl font-bold">{clientCount}</div>
          <div>Total</div>
          <button className="mt-2 bg-black text-white py-2 px-4 rounded-full text-sm hover:bg-gray-800 transition-colors dark:bg-white dark:text-black dark:hover:bg-gray-300 dark:border dark:border-white" onClick={(e) => { e.stopPropagation(); navigate('/clients'); }}>
            View All
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopHalf;
