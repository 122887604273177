import React, { useState, useEffect } from 'react';
import { 
  motion, 
  useScroll, 
  useTransform, 
  useMotionValue, 
  useVelocity, 
  useSpring, 
  useAnimationFrame 
} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faFileContract, faUserCircle, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import { ArrowRight } from "lucide-react";

const headerTexts = [
  'Simplify Your Client Billing',
  'Empower Your Freelance Career',
  'Streamline Your Workflow',
  'Join the Freelance Revolution',
  'Take Control of Your Business'
];

const wrap = (min, max, v) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const ParallaxContainer = ({ children, baseVelocity = 25 }) => {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  const x = useTransform(baseX, (v) => `${wrap(-100, 0, v)}%`);

  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1000);
    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax-container overflow-hidden whitespace-nowrap">
      <motion.div className="parallax-scroller" style={{ x }}>
        {[...Array(10)].map((_, i) => (
          <span key={i}>{children} </span>
        ))}
      </motion.div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <motion.div 
      whileHover={{ scale: 1.05, y: -5 }}
      transition={{ type: 'spring', stiffness: 300 }}
      className="bg-white dark:bg-black p-8 rounded-lg shadow-md dark:shadow-lg backdrop-blur-sm"
    >
      <FontAwesomeIcon icon={icon} size="2x" className="mb-4 text-[#161315] dark:text-white" />
      <h4 className="text-xl font-bold mb-3 text-[#161315] dark:text-white">{title}</h4>
      <p className="text-[#161315] dark:text-white leading-relaxed">{description}</p>
    </motion.div>
  );
};

export default function LandingPage() {
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
  const [typedText, setTypedText] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const currentText = headerTexts[currentHeaderIndex];

    const typingInterval = setInterval(() => {
      setTypedText(currentText.slice(0, currentIndex));
      currentIndex++;

      if (currentIndex > currentText.length) {
        clearInterval(typingInterval);
        setTimeout(() => {
          setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % headerTexts.length);
        }, 2500);
      }
    }, 80);

    return () => clearInterval(typingInterval);
  }, [currentHeaderIndex]);

  useEffect(() => {
    // Check if there's a hash in the URL
    if (window.location.hash) {
      // Wait a bit for the page to fully load
      setTimeout(() => {
        const element = document.querySelector(window.location.hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, []);

  return (
    <div className="min-h-screen text-[#161315] dark:text-white bg-white dark:bg-black">
      <div className="sticky top-0 z-50 backdrop-blur-sm">
        <NavigationBar isTransparent={document.documentElement.classList.contains('dark')} />
      </div>
      
      <main className="container mx-auto px-4">
        <section id="hero" className="flex flex-col items-center justify-center text-center py-16 bg-white dark:bg-black" style={{ minHeight: '85vh' }}>
          <div className="h-[120px] flex items-center justify-center mb-8">
            <h1 className="text-5xl md:text-7xl font-bold text-[#161315] dark:text-[#CBFF5B]">
              {typedText}
              <span className="animate-pulse">|</span>
            </h1>
          </div>
          <p className="text-2xl mb-10 text-[#161315] dark:text-white max-w-2xl">AI-powered billing and contracts for freelancers and small businesses</p>
          <motion.div
            className="relative"
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
          >
            <motion.div
              className="absolute inset-0 bg-[#CBFF5B] dark:bg-[#CBFF5B] rounded-lg shadow-lg dark:shadow-xl dark:shadow-[#00000080]"
              initial={false}
              animate={isHovered ? { scale: 1.05, rotate: -3, y: -5 } : { scale: 1, rotate: 0, y: 0 }}
              transition={{ type: 'spring', stiffness: 500, damping: 30 }}
            />
            <motion.button
              className="relative px-10 py-5 bg-[#CBFF5B] text-[#161315] dark:bg-[#CBFF5B] dark:text-[#161315] font-bold text-lg rounded-lg shadow-lg dark:shadow-xl dark:shadow-[#00000080] border-b-4 border-[#CBFF5B] dark:border-[#CBFF5B]"
              whileHover={{ scale: 1.05, rotate: 3, y: -5 }}
              whileTap={{ scale: 0.95, y: 0, borderBottomWidth: '2px' }}
              transition={{ type: 'spring', stiffness: 500, damping: 30 }}
              onClick={() => window.location.href='/signup'}
            >
              <span className="mr-3">Get Started</span>
              <motion.span
                initial={{ x: 0 }}
                animate={isHovered ? { x: 8 } : { x: 0 }}
                transition={{ type: 'spring', stiffness: 500, damping: 30 }}
              >
                <ArrowRight className="inline-block h-6 w-6" />
              </motion.span>
            </motion.button>
          </motion.div>
          <motion.p
            className="mt-3 text-sm text-[#161315] dark:text-white font-medium"
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            No credit card required • Start for free
          </motion.p>
          <div className="mt-16 w-full max-w-[1200px] mx-auto">
            <div className="relative" style={{ paddingBottom: '56.25%' }}>
              <img 
                src="/dashboard-image.jpg"
                alt="Dashboard Preview" 
                className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg dark:shadow-xl dark:shadow-[#00000080]"
              />
            </div>
          </div>
        </section>

        <ParallaxContainer>Design • Create • Inspire • Thrive • Succeed • Grow • Design • Create • Inspire • Thrive • Succeed • Grow</ParallaxContainer>

        {/* How It Works Section */}
        <section id="how-it-works" className="py-24 bg-white dark:bg-black">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl font-bold text-[#161315] dark:text-white mb-4">How Klorah Works</h2>
              <p className="text-xl text-gray-600 dark:text-gray-400">Get started in minutes, not hours</p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-12">
              {[
                {
                  title: "Create Documents",
                  description: "Generate professional agreements and invoices instantly with AI assistance",
                  icon: faFileContract
                },
                {
                  title: "Share & Sign",
                  description: "Send documents for e-signature and track their status in real-time",
                  icon: faUserCircle
                },
                {
                  title: "Get Paid",
                  description: "Accept payments directly through your invoices with integrated processing",
                  icon: faFileInvoice
                }
              ].map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className="flex flex-col items-center text-center"
                >
                  <div className="w-16 h-16 mb-6 flex items-center justify-center rounded-full bg-[#CBFF5B] dark:bg-[#CBFF5B]">
                    <FontAwesomeIcon icon={step.icon} className="h-8 w-8 text-[#161315] dark:text-[#161315]" />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-[#161315] dark:text-white">{step.title}</h3>
                  <p className="text-gray-600 dark:text-gray-400">{step.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <motion.section 
          id="pricing"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-32 bg-white dark:bg-black"
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-4xl font-bold text-[#161315] dark:text-white">Transparent, Value-Based Pricing</h2>
              <p className="mt-6 text-xl text-gray-600 dark:text-gray-400">
                Start with 2 free pro documents monthly, upgrade anytime
              </p>
            </div>

            <div className="mt-20 grid gap-10 lg:grid-cols-2 lg:gap-16 max-w-4xl mx-auto">
              {/* Basic Plan */}
              <div className="relative bg-white dark:bg-black rounded-2xl shadow-lg p-10 border border-gray-200 dark:border-gray-800">
                <div>
                  <h3 className="text-2xl font-semibold text-[#161315] dark:text-white">Basic</h3>
                  <div className="mt-5 flex items-baseline">
                    <span className="text-4xl font-bold text-[#161315] dark:text-white">Free</span>
                  </div>
                  <p className="mt-3 text-gray-600 dark:text-gray-400">
                    Perfect for getting started
                  </p>
                </div>

                <div className="mt-10">
                  <ul className="space-y-5">
                    <li className="flex items-center text-[#161315] dark:text-white">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      2 monthly pro documents
                    </li>
                    <li className="flex items-center text-[#161315] dark:text-white">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Full access to pro features
                    </li>
                    <li className="flex items-center text-[#161315] dark:text-white">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      AI document generation
                    </li>
                    <li className="flex items-center text-[#161315] dark:text-white">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Community support
                    </li>
                  </ul>
                </div>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="mt-10 w-full bg-[#161315] dark:bg-white text-white dark:text-[#161315] py-4 px-6 rounded-lg font-semibold transition-all shadow-lg"
                  onClick={() => window.location.href='/onboarding-selection'}
                >
                  Get Started
                </motion.button>
              </div>

              {/* Pro Plan */}
              <div className="relative bg-[#161315] dark:bg-white rounded-2xl shadow-xl p-10">
                <div className="absolute top-0 right-0 -translate-y-1/2 px-4 py-1 rounded-full text-sm font-semibold bg-[#CBFF5B] text-[#161315]">
                  Most Popular
                </div>
                <div>
                  <h3 className="text-2xl font-semibold text-white dark:text-[#161315]">Unlimited</h3>
                  <div className="mt-5">
                    <div className="flex items-baseline">
                      <span className="text-4xl font-bold text-white dark:text-[#161315]">$8</span>
                      <span className="ml-2 text-xl text-gray-400 dark:text-gray-600">/month</span>
                    </div>
                    <p className="mt-3 text-gray-400 dark:text-gray-600">
                      billed quarterly ($24 per quarter)
                    </p>
                  </div>
                </div>

                <div className="mt-10">
                  <ul className="space-y-5">
                    <li className="flex items-center text-white dark:text-[#161315]">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Unlimited documents
                    </li>
                    <li className="flex items-center text-white dark:text-[#161315]">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Premium features included
                    </li>
                    <li className="flex items-center text-white dark:text-[#161315]">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Priority support 24/7
                    </li>
                    <li className="flex items-center text-white dark:text-[#161315]">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Advanced analytics
                    </li>
                  </ul>
                </div>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="mt-10 w-full bg-[#CBFF5B] text-[#161315] py-4 px-6 rounded-lg font-semibold transition-all shadow-lg"
                  onClick={() => window.location.href='/onboarding-selection'}
                >
                  Upgrade to Unlimited
                </motion.button>

                <p className="mt-4 text-center text-sm text-gray-400 dark:text-gray-600">
                  Monthly billing available at $15/month
                </p>
              </div>
            </div>
          </div>
        </motion.section>

        <motion.section 
          id="features"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-32 bg-white dark:bg-black"
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-4xl font-bold mb-20 text-center text-[#161315] dark:text-white">Why Choose Klorah?</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
              <FeatureCard 
                icon={faFileInvoice} 
                title="Effortless Invoicing" 
                description="Create and send professional invoices in seconds with smart templates and automation."
              />
              <FeatureCard 
                icon={faFileContract} 
                title="Smart Agreements" 
                description="Generate legally-vetted agreements instantly with our advanced AI tools."
              />
              <FeatureCard 
                icon={faUserCircle} 
                title="Professional Profiles" 
                description="Build a stunning portfolio and attract high-value clients with AI-optimized profiles."
              />
              <FeatureCard 
                icon={faBriefcase} 
                title="Seamless Booking" 
                description="Enable clients to book and pay for your services with integrated scheduling."
              />
            </div>
          </div>
        </motion.section>

        <motion.section 
          id="cta"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center py-32"
        >
          <h2 className="text-5xl font-bold mb-8 text-[#161315] dark:text-white">Join the Future of Work</h2>
          <p className="text-2xl mb-14 text-[#161315] dark:text-white max-w-3xl mx-auto leading-relaxed">Klorah is more than a platform—it's a thriving community of successful freelancers redefining the future of work. Join us and embrace true financial independence.</p>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="bg-[#CBFF5B] text-[#161315] py-4 px-8 rounded-lg text-xl font-bold transition-all shadow-xl"
            onClick={() => window.location.href='/signup'}
          >
            Start Your Journey
          </motion.button>
        </motion.section>
      </main>
      <Footer />
    </div>
  );
}