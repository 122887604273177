import React from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const navigation = [
  { name: 'Features', href: '#features' },
  { name: 'Pricing', href: '#pricing' },
  { name: 'Blog', href: '/blog' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavigationBar = ({ isTransparent = false }) => {
  const bgClass = isTransparent 
    ? 'bg-transparent' 
    : 'bg-white dark:bg-black backdrop-filter backdrop-blur-lg bg-opacity-40 dark:bg-opacity-40';

  const textClass = isTransparent
    ? 'text-white'
    : 'text-gray-800 dark:text-gray-100';

  const hoverClass = isTransparent
    ? 'hover:bg-white hover:bg-opacity-10'
    : 'hover:bg-gray-100 dark:hover:bg-gray-700';

  const handleNavClick = (e, href) => {
    e.preventDefault();
    if (href.startsWith('#')) {
      if (window.location.pathname === '/') {
        const element = document.querySelector(href);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.location.href = '/' + href;
      }
    } else {
      window.location.href = href;
    }
  };

  return (
    <Disclosure as="nav" className={bgClass}>
      {({ open, close }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  <Disclosure.Button className={`relative inline-flex items-center justify-center rounded-md p-2 ${textClass} ${hoverClass} focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black`}>
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center p-4">
                  <Link to="/">
                    <img
                      className="h-8 w-auto"
                      src="/klorahLogo.png"
                      alt="Klorah"
                    />
                  </Link>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={(e) => handleNavClick(e, item.href)}
                      className={`inline-flex items-center px-1 pt-1 text-sm font-medium ${textClass} hover:font-bold`}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="flex-shrink-0">
                  <Link
                    to="/login"
                    className={`relative inline-flex items-center gap-x-1.5 rounded-lg ${isTransparent ? 'bg-white text-black' : 'bg-black dark:bg-white text-white dark:text-black'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-700 dark:hover:bg-gray-300`}
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  onClick={(e) => {
                    handleNavClick(e, item.href);
                    close();
                  }}
                  className={`block py-2 pl-3 pr-4 text-base font-medium ${textClass} ${hoverClass}`}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              <Disclosure.Button 
                as={Link} 
                to="/login" 
                className={`block py-2 pl-3 pr-4 text-base font-medium ${textClass} ${hoverClass}`}
              >
                Sign In
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavigationBar;