import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const questions = [
  {
    text: "How often do you send invoices to your clients?",
    options: ["Regularly", "Occasionally", "Rarely"]
  },
  {
    text: "Would you like to see how our AI can help with client agreements?",
    options: ["Yes, definitely!", "Maybe later", "Not right now"]
  },
  {
    text: "What's the most important feature in an invoicing tool for you?",
    options: ["Ease of use", "Customization", "Integration", "Cost"]
  }
];

export default function OnboardingQuestions() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();

  const currentQuestion = questions[currentQuestionIndex];

  const handleOptionSelect = (option) => {
    if (isTransitioning) return;

    setIsTransitioning(true);
    setAnswers([...answers, option]);

    setTimeout(() => {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      } else {
        // All questions answered, navigate to profile editing
        navigate('/dashboard', { state: { answers } });
      }
      setIsTransitioning(false);
    }, 1000);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-orange-400 via-orange-500 to-orange-600 p-4">
      <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">{currentQuestion.text}</h2>
        <div className="space-y-4">
          {currentQuestion.options.map((option, index) => (
            <button
              key={index}
              className={`flex items-center gap-3 w-full p-4 border border-gray-300 rounded-lg shadow-sm hover:border-orange-500 hover:shadow-md transition duration-300 ${isTransitioning ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleOptionSelect(option)}
              disabled={isTransitioning}
            >
              <CheckCircleIcon className="h-6 w-6 text-orange-500" />
              <span className="text-lg text-gray-800">{option}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}