import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import axios from 'axios';

const stripePromise = loadStripe('pk_live_51IuUXbLwpaCHfpZ1EZNNYaQYdXSIrE6NrdA3r7lUQOIq5tsdhC7MaTITIMFb3nW4yxqyppRd8C66QcxWhuoPC8aa00Ow2q0o0z');

const PRICE_IDS = {
  monthly: 'price_1QMa81LwpaCHfpZ1sR2trgFd',  // Replace with the monthly price ID
  quarterly: 'price_1QMa81LwpaCHfpZ1EEsytrKu'  // Replace with the quarterly price ID
};

export default function Billing() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPlan, setSelectedPlan] = useState(location.state?.billingPeriod || 'monthly');
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const auth = getAuth();

  const addLog = (message) => {
    setLogs(prevLogs => [...prevLogs, message]);
    console.log(message);
  };

  const createStripeCustomer = async () => {
    const userEmail = auth.currentUser?.email;
    const userId = auth.currentUser?.uid;
    if (!userEmail || !userId) {
      addLog("User email or ID is not available");
      return null;
    }

    try {
      addLog(`Creating Stripe customer for email: ${userEmail}`);
      const customerRes = await axios.post('https://klorah-fast-server-9266fe8d441a.herokuapp.com/create-customer', {
        email: userEmail,
      }, {
        headers: {
          Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
        },
      });

      const customerData = customerRes.data;
      addLog(`Stripe customer created/retrieved: ${customerData.stripe_customer_id}`);
      return { stripeCustomerId: customerData.stripe_customer_id, userId };
    } catch (error) {
      addLog(`Error in createStripeCustomer: ${error.response?.data || error.message}`);
      return null;
    }
  };

  const createCheckoutSession = async ({ stripeCustomerId, userId, billingPeriod }) => {
    try {
      addLog(`Creating ${billingPeriod} checkout session for user: ${userId}`);
      
      const sessionRes = await axios.post('https://klorah-fast-server-9266fe8d441a.herokuapp.com/createCheckoutSession', {
        userId,
        stripeCustomerId,
        priceId: PRICE_IDS[billingPeriod],
        billingPeriod: billingPeriod
      });
  
      const sessionData = sessionRes.data;
  
      if (!sessionData.sessionId) {
        throw new Error("Session ID is missing in the response");
      }
  
      addLog(`Checkout session created: ${sessionData.sessionId}`);
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId: sessionData.sessionId });
      if (error) {
        addLog(`Stripe Checkout error: ${error.message}`);
      } else {
        localStorage.setItem('isOnboardingComplete', 'true');
        addLog('Redirecting to Stripe Checkout');
      }
    } catch (error) {
      addLog(`Error in createCheckoutSession: ${error.response?.data || error.message}`);
    }
  };

  return (
    <div className="p-5">
      {!loading ? (
        <div className="max-w-md mx-auto">
          <h2 className="text-2xl font-bold mb-6">Choose Your Plan</h2>
          <div className="space-y-4">
            <div 
              className={`p-4 border rounded-lg cursor-pointer ${
                selectedPlan === 'monthly' ? 'border-orange-500 bg-orange-50' : 'border-gray-200'
              }`}
              onClick={() => setSelectedPlan('monthly')}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="font-semibold">Monthly Plan</h3>
                  <p className="text-gray-600">$15/month</p>
                </div>
                {selectedPlan === 'monthly' && (
                  <svg className="h-6 w-6 text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
            </div>

            <div 
              className={`p-4 border rounded-lg cursor-pointer ${
                selectedPlan === 'quarterly' ? 'border-orange-500 bg-orange-50' : 'border-gray-200'
              }`}
              onClick={() => setSelectedPlan('quarterly')}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="font-semibold">Quarterly Plan</h3>
                  <p className="text-gray-600">$8/month billed quarterly (Save 47%)</p>
                </div>
                {selectedPlan === 'quarterly' && (
                  <svg className="h-6 w-6 text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
            </div>

            <button 
              className="w-full bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition-colors"
              onClick={() => {
                setLoading(true);
                createStripeCustomer().then(data => {
                  if (data) {
                    createCheckoutSession({
                      ...data,
                      billingPeriod: selectedPlan
                    });
                  }
                });
              }}
            >
              Continue with {selectedPlan === 'monthly' ? 'Monthly' : 'Quarterly'} Plan
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-orange-500" />
          <p className="mt-4 text-gray-600">Setting up your subscription...</p>
        </div>
      )}
    </div>
  );
}