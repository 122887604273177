import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import app, { db } from '../Firebase';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const navigate = useNavigate();
    
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const handleLoginWithEmail = async (event) => {
        event.preventDefault();

        if (rememberMe) {
            localStorage.setItem('rememberMe', 'true');
        } else {
            localStorage.removeItem('rememberMe');
        }

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/dashboard');
        } catch (error) {
            handleAuthErrors(error);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const userRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(userRef);

            if (!docSnap.exists()) {
                await setDoc(userRef, {
                    userId: user.uid,
                    username: user.displayName || 'Anonymous',
                    email: user.email,
                    isPro: false,
                    invoicesCreated: 0,
                    agreementsCreated: 0,
                    stripeOnboarded: false,
                });
                navigate('/onboarding-questions');
            } else {
                if (rememberMe) {
                    localStorage.setItem('rememberMe', 'true');
                } else {
                    localStorage.removeItem('rememberMe');
                }
                navigate('/dashboard');
            }
        } catch (error) {
            setErrorMessage('Failed to sign in with Google. Please try again later.');
        }
    };

    function handleAuthErrors(error) {
        let errorMessage = '';
        if (error.code === 'auth/user-not-found') {
            errorMessage = 'Invalid email address.';
        } else if (error.code === 'auth/wrong-password') {
            errorMessage = 'Invalid password.';
        } else {
            errorMessage = 'Failed to sign in. Please try again later.';
        }
        setErrorMessage(errorMessage);
    }

    return (
        <div className="min-h-screen bg-white dark:bg-black flex items-center justify-center p-4 shadow-lg dark:shadow-2xl">
            <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="w-full max-w-lg bg-white/90 dark:bg-black/90 backdrop-blur-lg rounded-3xl shadow-2xl p-10 space-y-8"
            >
                <div className="text-center">
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <a href="/">
                            <img className="mx-auto h-16 w-auto" src="/klorahLogo.png" alt="Logo" />
                        </a>
                    </motion.div>
                    <h2 className="mt-8 text-4xl font-bold text-gray-900 dark:text-white">
                        Welcome back
                    </h2>
                    <p className="mt-3 text-gray-600 dark:text-gray-400">
                        New to Klorah?{' '}
                        <Link to="/signup" className="text-[#5BFF8F] hover:text-[#4BEF7F] font-semibold transition-colors">
                            Create an account
                        </Link>
                    </p>
                </div>

                <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleGoogleSignIn}
                    className="w-full flex items-center justify-center gap-3 bg-white dark:bg-black hover:bg-gray-50 dark:hover:bg-gray-900 text-gray-900 dark:text-white font-medium py-3 px-4 rounded-xl border border-gray-200 dark:border-gray-700 shadow-sm transition-all duration-200"
                >
                    <img src="/googleIcon.svg" alt="Google" className="w-6 h-6" />
                    Continue with Google
                </motion.button>

                <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300 dark:border-gray-700"></div>
                    </div>
                    <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white/90 dark:bg-black/90 text-gray-500 dark:text-gray-400">Or continue with email</span>
                    </div>
                </div>

                <form onSubmit={handleLoginWithEmail} className="space-y-6">
                    {errorMessage && (
                        <motion.div 
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="text-red-500 text-center text-sm"
                        >
                            {errorMessage}
                        </motion.div>
                    )}

                    <div className="space-y-4">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Email address
                            </label>
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="mt-1 block w-full px-4 py-3 bg-white dark:bg-black border border-gray-300 dark:border-gray-700 rounded-xl focus:ring-2 focus:ring-[#5BFF8F] focus:border-transparent transition-all duration-200"
                                placeholder="you@example.com"
                            />
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Password
                            </label>
                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="mt-1 block w-full px-4 py-3 bg-white dark:bg-black border border-gray-300 dark:border-gray-700 rounded-xl focus:ring-2 focus:ring-[#5BFF8F] focus:border-transparent transition-all duration-200"
                                placeholder="••••••••"
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <input
                                id="remember-me"
                                type="checkbox"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                                className="h-4 w-4 rounded border-gray-300 dark:border-gray-700 text-[#5BFF8F] focus:ring-[#5BFF8F]"
                            />
                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                                Remember me
                            </label>
                        </div>
                        <Link to="/auth/forgot-password" className="text-sm text-[#5BFF8F] hover:text-[#4BEF7F] transition-colors">
                            Forgot password?
                        </Link>
                    </div>

                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        type="submit"
                        className="w-full flex justify-center py-3 px-4 border border-transparent rounded-xl shadow-sm text-lg font-medium text-black dark:text-white bg-[#CBFF5B] hover:bg-[#BBEF4B] dark:bg-[#5BFF8F] dark:hover:bg-[#4BEF7F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5BFF8F] transition-all duration-200"
                    >
                        Sign in
                    </motion.button>
                </form>

                <div className="flex justify-center space-x-4 text-sm text-gray-600 dark:text-gray-400">
                    <Link to="/tos" className="hover:text-gray-900 dark:hover:text-gray-200 transition-colors">Terms of Service</Link>
                    <span>•</span>
                    <Link to="/privacy-policy" className="hover:text-gray-900 dark:hover:text-gray-200 transition-colors">Privacy Policy</Link>
                </div>
            </motion.div>
        </div>
    );
}