import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import NavigationBar from '../../NavigationBar';
import LoadingPage from '../Agreement/loadingPage';
import InvoicePreview from './InvoicePreview';

const stripePromise = loadStripe("pk_live_51IuUXbLwpaCHfpZ1EZNNYaQYdXSIrE6NrdA3r7lUQOIq5tsdhC7MaTITIMFb3nW4yxqyppRd8C66QcxWhuoPC8aa00Ow2q0o0z");

const PaymentForm = ({ clientSecret, price, invoiceId, theme }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setPaymentError('');

    if (!stripe || !elements) {
      setPaymentError('Stripe has not been initialized.');
      setIsProcessing(false);
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.error('Error submitting payment:', submitError);
      setPaymentError(submitError.message);
      setIsProcessing(false);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      console.error('Error confirming payment:', result.error);
      setPaymentError(result.error.message);
      setIsProcessing(false);
    } else if (result.paymentIntent.status === 'succeeded') {
      console.log('Payment succeeded, updating invoice...');
      try {
        const firestore = getFirestore();
        const invoiceRef = doc(firestore, 'invoices', invoiceId);
        await updateDoc(invoiceRef, { 
          status: 'paid',
          paidAt: new Date()
        });
        console.log('Invoice updated successfully');
        navigate(`/payment-confirmation/${invoiceId}`);
      } catch (error) {
        console.error('Error updating invoice:', error);
        setPaymentError('Payment successful, but there was an error updating the invoice. Please contact support.');
      }
    }
    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <PaymentElement id="payment-element" className="mb-6" />
      <button 
        type="submit" 
        className="w-full bg-green-600 text-white hover:bg-green-700 font-semibold py-3 px-4 rounded-lg transition duration-300 disabled:bg-gray-400" 
        disabled={!stripe || isProcessing}
      >
        {isProcessing ? 'Processing...' : `Pay $${price}`}
      </button>
      {paymentError && <div className="text-red-500 mt-4 text-sm">{paymentError}</div>}
    </form>
  );
};

const InvoicePreviewMobile = ({ invoiceData, invoiceId, onClick }) => {
  return (
    <div 
      onClick={onClick} 
      className="relative bg-white rounded-2xl shadow-lg p-6 mb-4 cursor-pointer group transition-all duration-300 hover:shadow-xl overflow-hidden"
    >
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-gradient-to-br from-green-50 to-transparent rounded-2xl opacity-50" />
      
      {/* Tiny Invoice Number */}
      <div className="absolute top-2 right-2">
        <div className="text-[10px] text-gray-400">
          <span className="font-mono">{invoiceId}</span>
        </div>
      </div>
 
      {/* Header */}
      <div className="relative flex items-center gap-3 mb-6">
        <div className="flex-shrink-0 w-10 h-10 bg-green-100 rounded-full flex items-center justify-center">
          <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-900">Invoice Summary</h3>
          <p className="text-sm text-gray-500">Quick overview of your invoice</p>
        </div>
      </div>
 
      {/* Main Content */}
      <div className="relative space-y-4">
        <div className="flex justify-between items-center p-3 bg-gray-50 rounded-lg group-hover:bg-gray-100 transition-colors duration-200">
          <span className="text-sm text-gray-500">Service</span>
          <span className="text-sm font-medium text-gray-900">{invoiceData.gig}</span>
        </div>
 
        <div className="flex justify-between items-center p-3 bg-green-50 rounded-lg group-hover:bg-green-100 transition-colors duration-200">
          <span className="text-sm text-gray-500">Amount</span>
          <div className="flex items-center gap-1">
            <span className="text-sm text-gray-400">{invoiceData.currency}</span>
            <span className="text-lg font-semibold text-gray-900">${invoiceData.price}</span>
          </div>
        </div>
      </div>
 
      {/* View Full Button */}
      <div className="relative mt-6 flex justify-end">
        <button className="group/btn flex items-center gap-2 text-sm font-medium text-green-600 hover:text-green-700 transition-colors duration-200">
          View Full Invoice
          <svg 
            className="w-4 h-4 transform transition-transform duration-200 group-hover/btn:translate-x-1" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  );
 };

const PaymentPage = () => {
  const { invoiceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState('');
  const [invoiceData, setInvoiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [invoiceTheme, setInvoiceTheme] = useState('default');
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const [showFullPreview, setShowFullPreview] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;

  const themes = {
    default: { primaryColor: '#ffffff', secondaryColor: '#f3f4f6', textColor: '#000000' },
    modern: { primaryColor: '#e0f2f1', secondaryColor: '#b2dfdb', textColor: '#004d40' },
    classic: { primaryColor: '#fff8e1', secondaryColor: '#ffecb3', textColor: '#ff6f00' },
    elegant: { primaryColor: '#fce4ec', secondaryColor: '#f8bbd0', textColor: '#880e4f' },
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const firestore = getFirestore();
        const invoiceDocRef = doc(firestore, 'invoices', invoiceId);
        const invoiceDocSnap = await getDoc(invoiceDocRef);

        if (!invoiceDocSnap.exists()) {
          setError('Invoice not found.');
          return;
        }

        const invoice = invoiceDocSnap.data();
        setInvoiceData(invoice);
        setInvoiceTheme(invoice.theme || 'default');

        if (invoice.status === 'paid') {
          navigate(`/payment-confirmation/${invoiceId}`);
          return;
        }

        const response = await fetch('https://klorah-fast-server-9266fe8d441a.herokuapp.com/create-payment-intent', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            amount: invoice.price * 100,
            currency: invoice.currency,
            description: `${invoice.gig} for ${invoice.customer}`,
            invoiceId: invoiceId,
            connectedAccountId: invoice.connectedAccountId,
            userId: invoice.userId,
          }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          setError(`Failed to create payment intent: ${errorText}`);
          return;
        }

        const paymentIntent = await response.json();
        setClientSecret(paymentIntent.clientSecret);
      } catch (error) {
        console.error('Error fetching invoice:', error);
        setError(`An error occurred while fetching the invoice: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoice();
  }, [invoiceId, navigate]);

  if (isLoading || isConfirmingPayment) return <LoadingPage />;

  if (error) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center px-4">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  const selectedTheme = themes[invoiceTheme] || themes.default;

  return (
    <div>
      {/* Navigation should be highest priority */}
      <div className="relative z-50">
        {user && <NavigationBar />}
      </div>

      {/* Main content should be below navigation but above any modals */}
      <div className="relative z-0">
        {/* Mobile View */}
        <div className="lg:hidden">
          <div 
            className="fixed inset-0 z-40 bg-black bg-opacity-50 transition-opacity duration-300"
            style={{ display: showFullPreview ? 'block' : 'none' }}
            onClick={() => setShowFullPreview(false)}
          >
          </div>
          
          <div className={`fixed inset-x-0 bottom-0 z-40 transform transition-transform duration-300 ease-in-out
                        ${showFullPreview ? 'translate-y-0' : 'translate-y-full'}`}>
            <div className="bg-white rounded-t-2xl shadow-xl max-h-[80vh] overflow-y-auto">
              <div className="sticky top-0 bg-white border-b border-gray-100 p-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-semibold">Invoice Details</h2>
                  <button 
                    onClick={() => setShowFullPreview(false)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="p-4">
                {invoiceData && (
                  <InvoicePreview 
                    invoiceData={invoiceData}
                    invoiceTheme={invoiceTheme}
                    setIsThemeModalOpen={setIsThemeModalOpen}
                    invoiceId={invoiceId}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="px-4 pt-6">
            {invoiceData && (
              <InvoicePreviewMobile 
                invoiceData={invoiceData}
                invoiceId={invoiceId}
                onClick={() => setShowFullPreview(true)}
              />
            )}
          </div>
        </div>

        <div className="py-8 px-4 md:px-8 lg:px-12">
          <div className="max-w-[1800px] mx-auto">
            <div className="flex flex-col lg:flex-row gap-10">
              {/* Desktop Invoice Preview */}
              <div className="hidden lg:block lg:w-[60%] h-fit">
                {invoiceData && (
                  <InvoicePreview 
                    invoiceData={invoiceData}
                    invoiceTheme={invoiceTheme}
                    setIsThemeModalOpen={setIsThemeModalOpen}
                    invoiceId={invoiceId}
                  />
                )}
              </div>

              {/* Payment Section */}
              <div className="lg:w-[40%]">
                <div className="sticky top-8">
                  {invoiceData && invoiceData.status !== 'paid' && clientSecret && (
                    <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
                      <div className="px-8 pt-8 pb-6 border-b border-gray-100">
                        <div className="flex items-center gap-3 mb-2">
                          <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse"></div>
                          <h2 className="text-2xl font-medium text-gray-900">Complete Payment</h2>
                        </div>
                        <p className="text-gray-500">Safe and secure checkout</p>
                      </div>

                      <div className="p-8">
                        {/* Price Display */}
                        <div className="mb-8 p-4 rounded-xl bg-gradient-to-br from-green-50 to-green-100 border border-green-100">
                          <div className="flex justify-between items-center">
                            <span className="text-gray-600">Amount due</span>
                            <div className="text-right">
                              <div className="text-2xl font-semibold text-green-600">
                                ${invoiceData.price}
                              </div>
                              <div className="text-sm text-gray-500">
                                {invoiceData.currency}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Payment Form */}
                        <Elements stripe={stripePromise} options={{ 
                          clientSecret,
                          appearance: {
                            theme: 'stripe',
                            variables: {
                              colorPrimary: '#16a34a',
                              colorBackground: '#ffffff',
                              borderRadius: '8px',
                            },
                          },
                        }}>
                          <PaymentForm 
                            clientSecret={clientSecret} 
                            price={invoiceData.price} 
                            invoiceId={invoiceId} 
                            theme={selectedTheme}
                          />
                        </Elements>

                        {/* Security Badges */}
                        <div className="mt-8 pt-6 border-t border-gray-100">
                          <div className="flex items-center justify-center gap-4">
                            <span className="text-sm text-black">
                              Powered by <a href="https://klorah.app" className="text-green-600 hover:underline">Klorah</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;