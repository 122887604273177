import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import NavigationBar from '../../NavigationBar';

const EditProject = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [tasks, setTasks] = useState([]);
  const [taskName, setTaskName] = useState('');
  const [relativeDeadline, setRelativeDeadline] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          navigate('/login');
          return;
        }

        const db = getFirestore();
        const projectRef = doc(db, 'projects', projectId);
        const projectSnap = await getDoc(projectRef);

        if (projectSnap.exists() && projectSnap.data().userId === user.uid) {
          const projectData = projectSnap.data();
          setProject(projectData);
          setProjectName(projectData.projectName);
          setDueDate(projectData.dueDate.toDate().toISOString().split('T')[0]);
          setTasks(projectData.tasks);
        } else {
          setError('Project not found or access denied.');
        }
      } catch (error) {
        console.error('Error fetching project:', error);
        setError('Failed to load project. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId, navigate]);

  const addTask = () => {
    if (!taskName || !relativeDeadline) {
      setError('Please provide both a task name and deadline');
      return;
    }
    setTasks([
      ...tasks,
      {
        taskName,
        relativeDeadline: parseInt(relativeDeadline, 10),
        status: 'incomplete',
        id: Date.now()
      },
    ]);
    setTaskName('');
    setRelativeDeadline('');
    setError('');
  };

  const removeTask = (taskId) => {
    setTasks(tasks.filter(task => task.id !== taskId));
  };

  const editTask = (taskId, newName, newDeadline) => {
    setTasks(tasks.map(task => {
      if (task.id === taskId) {
        return {
          ...task,
          taskName: newName || task.taskName,
          relativeDeadline: newDeadline ? parseInt(newDeadline) : task.relativeDeadline,
          isEditing: !task.isEditing
        };
      }
      return { ...task, isEditing: false };
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!projectName || !dueDate) {
      setError('Please provide both a project name and due date');
      return;
    }
    if (tasks.length === 0) {
      setError('Add at least one task to your project');
      return;
    }

    try {
      setLoading(true);
      const db = getFirestore();
      await updateDoc(doc(db, 'projects', projectId), {
        projectName,
        dueDate: new Date(dueDate),
        tasks,
        updatedAt: new Date(),
      });

      setShowSuccessToast(true);
      setTimeout(() => {
        navigate(`/project/${projectId}`);
      }, 1500);
    } catch (error) {
      console.error('Error updating project:', error);
      setError('Unable to update project. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-800 dark:border-white"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-black">
        <div className="text-center p-8 bg-white dark:bg-black rounded-xl shadow-lg max-w-md border border-gray-200 dark:border-white">
          <div className="text-red-500 text-5xl mb-4">⚠️</div>
          <p className="text-gray-800 dark:text-white mb-6">{error}</p>
          <button
            onClick={() => navigate('/projects')}
            className="px-6 py-3 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-all duration-200 shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
          >
            Back to Projects
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <NavigationBar />
      <div className="min-h-screen p-6 md:p-8">
        <div className="max-w-4xl mx-auto">
          <div className="bg-white dark:bg-black rounded-2xl shadow-xl overflow-hidden border border-gray-200 dark:border-white">
            <div className="p-6 md:p-8 border-b border-gray-200 dark:border-white">
              <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
                <div className="flex items-center gap-3">
                  <input
                    type="text"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    className="text-3xl font-bold text-gray-900 dark:text-white bg-transparent border-none focus:ring-0"
                    placeholder="Enter project name"
                  />
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex flex-col items-end">
                    <span className="text-sm text-gray-500 dark:text-white/60">Due Date</span>
                    <input
                      type="date"
                      value={dueDate}
                      onChange={(e) => setDueDate(e.target.value)}
                      min={new Date().toISOString().split('T')[0]}
                      className="text-md font-medium text-gray-900 dark:text-white bg-transparent border-none focus:ring-0"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="p-6 md:p-8">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Tasks</h2>
                <div className="flex gap-2">
                  <div className="flex flex-col md:flex-row gap-4">
                    <input
                      type="text"
                      value={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                      placeholder="New task name"
                      className="rounded-lg border border-gray-200 dark:border-gray-700 px-4 py-2 bg-white dark:bg-black"
                    />
                    <select
                      value={relativeDeadline}
                      onChange={(e) => setRelativeDeadline(e.target.value)}
                      className="rounded-lg border border-gray-200 dark:border-gray-700 px-4 py-2 bg-white dark:bg-black appearance-none pr-10 pl-4"
                    >
                      <option value="">&nbsp;&nbsp;Days before due&nbsp;&nbsp;</option>
                      <option value="1">1 day before</option>
                      <option value="3">3 days before</option>
                      <option value="7">7 days before</option>
                      <option value="14">14 days before</option>
                    </select>
                    <button
                      onClick={addTask}
                      disabled={!taskName || !relativeDeadline}
                      className="px-6 py-2 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Add Task
                    </button>
                  </div>
                </div>
              </div>

              {tasks.length > 0 ? (
                <div className="space-y-4">
                  {tasks.map((task) => (
                    <div
                      key={task.id}
                      className="group bg-gray-50 dark:bg-black rounded-xl p-4 transition-all duration-200 hover:shadow-md border border-gray-200 dark:border-white"
                    >
                      <div className="flex items-center justify-between gap-4">
                        <div className="flex-1">
                          <input
                            type="text"
                            value={task.taskName}
                            onChange={(e) => editTask(task.id, e.target.value, task.relativeDeadline)}
                            className="text-lg font-medium text-gray-900 dark:text-white bg-transparent border-none focus:ring-0 w-full"
                          />
                          <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-white/60">
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <select
                              value={task.relativeDeadline}
                              onChange={(e) => editTask(task.id, task.taskName, e.target.value)}
                              className="bg-transparent border-none focus:ring-0"
                            >
                              <option value="1">1 day before due date</option>
                              <option value="3">3 days before due date</option>
                              <option value="7">7 days before due date</option>
                              <option value="14">14 days before due date</option>
                              <option value="30">30 days before due date</option>
                            </select>
                          </div>
                        </div>
                        <button
                          onClick={() => removeTask(task.id)}
                          className="text-red-500 hover:text-red-600 px-4 py-2 rounded-lg hover:bg-red-50 dark:hover:bg-red-900/20"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-12 bg-gray-50 dark:bg-black rounded-xl border border-gray-200 dark:border-white">
                  <p className="text-gray-500 dark:text-white/60">No tasks added yet.</p>
                </div>
              )}
            </div>

            <div className="p-6 md:p-8 border-t border-gray-200 dark:border-white">
              <div className="flex justify-between">
                <button
                  onClick={() => navigate(`/project/${projectId}`)}
                  className="w-full md:w-auto px-6 py-3 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-all duration-200 flex items-center justify-center gap-2"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                  </svg>
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  className="w-full md:w-auto px-6 py-3 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-all duration-200"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSuccessToast && (
        <div className="fixed bottom-8 right-8 bg-green-500 text-white px-6 py-4 rounded-xl shadow-lg">
          Project updated successfully!
        </div>
      )}
    </div>
  );
};

export default EditProject;