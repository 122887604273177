import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFileInvoiceDollar, FaFileContract, FaProjectDiagram, FaTrash, FaEllipsisV } from 'react-icons/fa';

const ClientCard = ({ client, onDelete }) => {
  const navigate = useNavigate();

  const handleAction = async (action, e) => {
    e.stopPropagation(); // Prevent card click when clicking actions
    
    const payload = {
      clientId: client.id,
      clientName: client.name,
      clientEmail: client.email,
    };

    switch(action) {
      case 'project':
        // Pass client info to project creation
        navigate('/projects/new', { 
          state: { 
            ...payload,
            source: 'client_card'
          }
        });
        break;

      case 'invoice':
        // Pass client info to invoice creation
        navigate('/create-invoice', {
          state: {
            ...payload,
            customer: client.name, // Match your invoice form fields
            userEmail: client.email,
            source: 'client_card'
          }
        });
        break;

      case 'agreement':
        // Pass client info to agreement creation
        navigate('/create-agreement', {
          state: {
            ...payload,
            clientName: client.name, // Match your agreement form fields
            source: 'client_card'
          }
        });
        break;

      case 'details':
        navigate(`/client/${client.id}`);
        break;

      default:
        console.error('Unknown action:', action);
    }
  };

  return (
    <div 
      onClick={() => handleAction('details')} 
      className="bg-white dark:bg-black rounded-2xl p-6 border border-gray-200 dark:border-white hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 cursor-pointer group"
    >
      <div className="flex justify-between items-start mb-6">
        <div className="space-y-1">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-gray-700 dark:group-hover:text-gray-200 transition-colors">
            {client.name}
          </h3>
          <p className="text-sm text-gray-600 dark:text-white/60">{client.email}</p>
          {client.phone && (
            <p className="text-sm text-gray-600 dark:text-white/60">{client.phone}</p>
          )}
        </div>
        <div className="flex items-center gap-2">
          <button 
            onClick={(e) => {
              e.stopPropagation();
              onDelete(client.id);
            }}
            className="p-2 text-gray-400 hover:text-red-500 transition-colors rounded-full hover:bg-red-50 dark:hover:bg-red-900/30"
            title="Delete client"
          >
            <FaTrash className="w-4 h-4" />
          </button>
          <button 
            onClick={(e) => e.stopPropagation()}
            className="p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200 transition-colors rounded-full hover:bg-gray-100 dark:hover:bg-gray-800"
          >
            <FaEllipsisV className="w-4 h-4" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-3 mb-4">
        <button
          onClick={(e) => handleAction('project', e)}
          className="flex flex-col items-center gap-2 p-3 rounded-xl bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300 hover:bg-blue-100 dark:hover:bg-blue-800/50 transition-all group/btn"
        >
          <FaProjectDiagram className="w-5 h-5 transition-transform duration-300 group-hover/btn:scale-110" />
          <span className="text-xs font-medium">Project</span>
        </button>
        <button
          onClick={(e) => handleAction('invoice', e)}
          className="flex flex-col items-center gap-2 p-3 rounded-xl bg-green-50 dark:bg-green-900/30 text-green-600 dark:text-green-300 hover:bg-green-100 dark:hover:bg-green-800/50 transition-all group/btn"
        >
          <FaFileInvoiceDollar className="w-5 h-5 transition-transform duration-300 group-hover/btn:scale-110" />
          <span className="text-xs font-medium">Invoice</span>
        </button>
        <button
          onClick={(e) => handleAction('agreement', e)}
          className="flex flex-col items-center gap-2 p-3 rounded-xl bg-purple-50 dark:bg-purple-900/30 text-purple-600 dark:text-purple-300 hover:bg-purple-100 dark:hover:bg-purple-800/50 transition-all group/btn"
        >
          <FaFileContract className="w-5 h-5 transition-transform duration-300 group-hover/btn:scale-110" />
          <span className="text-xs font-medium">Agreement</span>
        </button>
      </div>

      <div className="pt-4 border-t border-gray-100 dark:border-white/10">
        <div className="flex items-center justify-between text-sm">
          <div className="text-gray-600 dark:text-white/60">
            <span className="font-medium">0</span> active projects
          </div>
          <div className="flex items-center text-gray-600 dark:text-white/60 group-hover:text-gray-900 dark:group-hover:text-white transition-colors">
            View Details
            <svg className="w-4 h-4 ml-1 transition-transform duration-300 group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;