import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native-web';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavigationBar from '../NavigationBar';
import BottomHalf from './BottomHalf';
import TopHalf from './TopHalf';
import useCurrentUser from '../Authentication/currentUser';
import FeedbackButtonWithForm from '../FeedbackButtonWithForm';
import useAuthAndEmailVerification from '../Authentication/useAuthandEmailVerification';
import { getAuth } from 'firebase/auth';
import { VerificationBanner } from './VerificationBanner';

const Dashboard = () => {
  const { isAuthenticated, isEmailVerified } = useAuthAndEmailVerification();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [showVerificationBanner, setShowVerificationBanner] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('checking');
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', handleResize);
    return () => {
      // Clean up event listener
      Dimensions.removeEventListener('change', handleResize);
    };
  }, []);

  useEffect(() => {
    // Listen for changes in color scheme
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setIsDarkMode(e.matches);
    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isEmailVerified) {
      setShowVerificationBanner(true);
    } else {
      setShowVerificationBanner(false);
    }

    const checkSubscription = async () => {
      if (isAuthenticated && currentUser) {
        try {
          const auth = getAuth();
          const user = auth.currentUser;
          const idToken = await user.getIdToken();
          const response = await axios.post(
            'https://klorah-fast-server-9266fe8d441a.herokuapp.com/check-and-update-subscription',
            {},
            {
              headers: {
                Authorization: `Bearer ${idToken}`
              }
            }
          );

          if (response.data.status === 'success') {
            setSubscriptionStatus('active');
          } else if (response.data.status === 'no_subscription') {
            setSubscriptionStatus('inactive');
          } else {
            setSubscriptionStatus('error');
          }
        } catch (error) {
          console.error('Error checking subscription:', error);
          setSubscriptionStatus('error');
        }
      }
    };

    checkSubscription();
  }, [isAuthenticated, isEmailVerified, currentUser]);

  const initiateStripeOnboarding = () => {
    navigate('/stripe-ob');
  };

  if (!isAuthenticated) {
    return null;
  }

  const gradientColors = {
    top: 'hsla(120, 60%, 50%, 0.15)',    // Green tint at top
    middle: 'rgba(0, 0, 0, 0)',          // Fully transparent in middle
    bottom: 'rgba(0, 0, 0, 0)'           // Fully transparent at bottom
  };

  return (
    <View style={styles.container}>
      <View style={[
        styles.gradientContainer,
        {
          background: `linear-gradient(
            to bottom,                       
            ${gradientColors.top},
            ${gradientColors.middle} 10%,
            ${gradientColors.bottom}
          )`
        }
      ]}>
        <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
          <NavigationBar />
        </div>
        {showVerificationBanner && <VerificationBanner />}
        {currentUser && !currentUser.stripeOnboarded && (
          <div className="flex items-center justify-center gap-x-6 bg-red-600 px-3 py-2 sm:px-3.5">
            <p className="text-xs sm:text-sm leading-6 text-white text-center">
              <a onClick={initiateStripeOnboarding}>
                <strong className="font-semibold">Additional information required</strong>
                <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                {windowWidth > 640 ? 
                  'You will need to provide more information to start accepting payments' :
                  'More info needed for payments'
                }&nbsp;
                <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        )}
        <View style={[styles.topFrameContainer, windowWidth <= 640 && styles.mobileTopFrameContainer]}>
          <TopHalf style={styles.topHalf} />
          <BottomHalf 
            bottom={windowWidth <= 640 ? '5%' : '9%'} 
            height={'auto'} 
            style={[styles.bottomHalf, windowWidth <= 640 && styles.mobileBottomHalf]} 
          />
        </View>
        <FeedbackButtonWithForm />
        <VerificationBanner />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100%',
    position: 'relative',
  },
  gradientContainer: {
    flex: 1,
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topFrameContainer: {
    position: 'relative',
    top: 20,
    alignItems: 'center',
    width: '100%',
    gap: 5,
    flex: 1,
    paddingHorizontal: 16,
  },
  mobileTopFrameContainer: {
    top: 10,
    gap: 2,
    paddingHorizontal: 8,
  },
  topHalf: {
    marginBottom: 5,
    width: '100%',
  },
  bottomHalf: {
    marginTop: -5,
    flex: 1,
    width: '100%',
  },
  mobileBottomHalf: {
    marginTop: -2,
  }
});

export default Dashboard;