import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import useCurrentUser from '../../Authentication/currentUser';

export default function InvoicePreview({ invoiceData, invoiceTheme, setIsThemeModalOpen, invoiceId }) {
  const { username } = useCurrentUser();

  const formatDate = (date) => {
    if (!date) return 'Not specified';
    try {
      if (date.toDate) return date.toDate().toLocaleDateString();
      if (typeof date === 'string') return new Date(date).toLocaleDateString();
      if (date instanceof Date) return date.toLocaleDateString();
      return 'Invalid date';
    } catch (error) {
      return 'Invalid date';
    }
  };

  const themes = {
    default: {
      accentColor: '#000000',
    },
    modern: {
      accentColor: '#10b981',
    },
    elegant: {
      accentColor: '#6366f1',
    }
  };

  const selectedTheme = themes[invoiceTheme] || themes.default;

  const generatePDF = (e) => {
    e.preventDefault();
    
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4'
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 40;
    let y = margin;

    // Header section with border
    doc.setFillColor(255, 255, 255);
    doc.rect(margin, y, pageWidth - (margin * 2), 100, 'F');
    
    // INVOICE text
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(24);
    doc.setTextColor(0, 0, 0); // Always black for PDF
    doc.text('INVOICE', margin + 20, y + 40);

    // Date and Invoice number
    doc.setFontSize(11);
    doc.setTextColor(100, 100, 100);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, margin + 20, y + 60);
    if (invoiceId) {
      doc.text(`Invoice #: ${invoiceId}`, margin + 20, y + 75);
    }

    // Freelancer name (right aligned)
    doc.setFont('helvetica', 'bold');
    doc.text(username || 'Freelancer', pageWidth - margin - 20, y + 40, { align: 'right' });

    // Bill To section
    y += 120;
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text('Bill To', margin + 20, y);
    
    y += 25;
    doc.setFontSize(12);
    doc.text(invoiceData.customer || 'Customer Name', margin + 20, y);

    // Invoice items table
    y += 40;
    doc.autoTable({
      startY: y,
      head: [['Description', 'Amount']],
      body: [[
        invoiceData.gig || 'Service Description',
        `${invoiceData.currency} ${invoiceData.price || '0.00'}`
      ]],
      theme: 'plain',
      styles: {
        fontSize: 12,
        textColor: [0, 0, 0],
        lineWidth: 1,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 12,
        fontStyle: 'bold',
        lineWidth: 1,
        lineColor: [200, 200, 200],
      },
      columnStyles: {
        0: { cellWidth: 400 },
        1: { cellWidth: 100, halign: 'right' }
      },
      margin: { left: margin + 20, right: margin + 20 },
      tableLineColor: [200, 200, 200],
    });

    // Total section
    const finalY = doc.autoTable.previous.finalY + 20;
    doc.setFont('helvetica', 'bold');
    doc.text('Total:', pageWidth - margin - 120, finalY);
    doc.text(
      `${invoiceData.currency} ${invoiceData.price || '0.00'}`,
      pageWidth - margin - 20,
      finalY,
      { align: 'right' }
    );

    // Footer
    const footerY = finalY + 40;
    doc.setFillColor(250, 250, 250);
    doc.rect(margin, footerY, pageWidth - (margin * 2), 80, 'F');

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(12);
    doc.setTextColor(100, 100, 100);
    doc.text(`Due Date: ${formatDate(invoiceData.dueDate)}`, margin + 20, footerY + 30);
    
    doc.setFontSize(11);
    doc.text('Thank you for your business', margin + 20, footerY + 50);

    const customerName = invoiceData.customer ? invoiceData.customer.replace(/\s+/g, '_') : 'unnamed';
    const date = new Date().toISOString().split('T')[0];
    const filename = invoiceId 
      ? `invoice-${invoiceId}-${customerName}-${date}.pdf` 
      : `invoice-${customerName}-${date}.pdf`;
    
    doc.save(filename);
  };

  return (
    <div className="mt-8 pb-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-medium dark:text-white">Invoice Preview</h2>
        <div className="flex gap-3">
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsThemeModalOpen(true);
            }}
            className="px-4 py-2 text-sm border dark:border-white rounded-lg hover:bg-gray-50 dark:hover:bg-black dark:text-white transition-colors"
          >
            Change Theme
          </button>
          <button
            onClick={generatePDF}
            className="px-4 py-2 text-sm bg-black text-white dark:bg-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-white/90 transition-colors"
          >
            Download PDF
          </button>
        </div>
      </div>

      <div className="border dark:border-white rounded-lg overflow-hidden">
        {/* Header */}
        <div className="p-8 bg-white dark:bg-black border-b dark:border-white">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-3xl font-bold mb-4 text-black dark:text-white">
                INVOICE
              </h1>
              <p className="text-sm text-gray-600 dark:text-white">
                Date: {new Date().toLocaleDateString()}
              </p>
              {invoiceId && (
                <p className="text-sm text-gray-600 dark:text-white">
                  Invoice #: {invoiceId}
                </p>
              )}
            </div>
            <div>
              <p className="font-medium dark:text-white">{username}</p>
            </div>
          </div>
        </div>

        {/* Bill To Section */}
        <div className="p-8 bg-white dark:bg-black border-b dark:border-white">
          <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-4">Bill To</h3>
          <p className="dark:text-white">{invoiceData.customer || 'Customer Name'}</p>
        </div>

        {/* Invoice Items */}
        <div className="p-8 bg-white dark:bg-black border-b dark:border-white">
          <table className="w-full">
            <thead>
              <tr className="border-b dark:border-white">
                <th className="text-left py-3 text-sm font-medium text-gray-900 dark:text-white">Description</th>
                <th className="text-right py-3 text-sm font-medium text-gray-900 dark:text-white">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-4 dark:text-white">{invoiceData.gig || 'Service Description'}</td>
                <td className="py-4 text-right dark:text-white">
                  {invoiceData.currency} {invoiceData.price || '0.00'}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className="pt-6 text-right font-medium dark:text-white">Total:</td>
                <td className="pt-6 text-right font-medium dark:text-white">
                  {invoiceData.currency} {invoiceData.price || '0.00'}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        {/* Footer */}
        <div className="p-8 bg-white dark:bg-black">
          <p className="text-sm text-gray-600 dark:text-white mb-2">
            Due Date: {formatDate(invoiceData.dueDate)}
          </p>
          <p className="text-sm text-gray-500 dark:text-white">
            Thank you for your business
          </p>
        </div>
      </div>
    </div>
  );
}