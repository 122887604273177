import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun, faDesktop } from '@fortawesome/free-solid-svg-icons';

const DarkModeButton = ({ onDarkModeChange = () => {} }) => {
  const [theme, setTheme] = useState(() => {
    // Check localStorage first
    const localTheme = window.localStorage.getItem('theme');
    if (localTheme) return localTheme;

    // If no localStorage value, check system preference
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark');
      return 'system';
    }
    document.documentElement.classList.remove('dark');
    return 'system';
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleChange = () => {
      if (theme === 'system') {
        const isDark = mediaQuery.matches;
        document.documentElement.classList.toggle('dark', isDark);
        onDarkModeChange(isDark);
      }
    };

    // Set initial state
    handleChange();

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [theme, onDarkModeChange]);

  const updateTheme = (newTheme) => {
    setTheme(newTheme);
    if (newTheme === 'system') {
      const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      document.documentElement.classList.toggle('dark', isDark);
      onDarkModeChange(isDark);
    } else {
      const isDark = newTheme === 'dark';
      document.documentElement.classList.toggle('dark', isDark);
      onDarkModeChange(isDark);
    }
    window.localStorage.setItem('theme', newTheme);
  };

  const getIcon = () => {
    switch(theme) {
      case 'light': return faSun;
      case 'dark': return faMoon;
      default: return faDesktop;
    }
  };

  const cycleTheme = () => {
    const themes = ['system', 'light', 'dark'];
    const currentIndex = themes.indexOf(theme);
    const nextTheme = themes[(currentIndex + 1) % themes.length];
    updateTheme(nextTheme);
  };

  const getThemeLabel = () => {
    switch(theme) {
      case 'light': return 'Light Mode';
      case 'dark': return 'Dark Mode';
      default: return 'System Theme';
    }
  };

  return (
    <div className="fixed bottom-4 left-4 z-50 group">
      <button
        onClick={cycleTheme}
        className="p-3 rounded-full shadow-lg bg-white dark:bg-black hover:bg-gray-100 dark:hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-[#CBFF5B] transition-all duration-200"
        aria-label="Toggle color theme"
      >
        <FontAwesomeIcon 
          icon={getIcon()} 
          className="text-black dark:text-white text-lg"
          title={`Current theme: ${getThemeLabel()}`}
        />
      </button>
      <div className="absolute left-0 mt-2 bg-white dark:bg-black rounded-md shadow-lg p-3 text-sm text-gray-600 dark:text-gray-400 whitespace-nowrap">
        <div className="font-medium">{getThemeLabel()}</div>
        <div className="text-xs mt-1">Click to cycle themes</div>
      </div>
    </div>
  );
};

export default DarkModeButton;
